import React, { useEffect, useRef, useState } from 'react';
import { useErrorBoundary } from 'react-error-boundary';
import withRouter from '../../hoc/withRouter';
import { withReducer } from '../../store/withReducer';
import { InsightLabels } from './../../labels';
import Datepicker from './../../components/Calender/Datepicker';
import DatePicker from "react-datepicker";
import Loader from "./../../components/Loader/Loader";
import SectionButtons from "./../../components/Layouts/SectionButtons";
import * as exportActions from "./../../store/Actions/index";
import * as ActionTypes from "./../../store/Actions/ActionTypes";
import { useSelector, useDispatch } from 'react-redux';
import { AppsState, EventSourceState, ExportDatesState, ExportRequestSourceFilterState, LanguageState } from './../../store/Reducers';
import qs from 'querystring';
import './ExportList.scss';
import { useNavigate } from 'react-router-dom';
import { CommonUtils } from './../../utils/CommonUtils';
import exportReducer, { ExportState } from 'store/Reducers/Export/export';
import { Tooltip } from 'react-bootstrap';
import { Dropdown, OverlayTrigger, Tab, Tabs, ToggleButton, ToggleButtonGroup } from 'react-bootstrap';
import moment from 'moment';
import { deleteCall, getCall, patchCall, postCall, putCall } from 'utils/ApiCallActions';
import { ApiRelativePaths, RetryApi } from 'utils/GlobalConstants';
import { toast } from 'react-toastify';
import { GetAppDropdown, GetDataSourceDropdown, GetEventSourceDropdown, GetGuideDropdown, GetPlayTypeDropdown, GetTooltipDropdown } from "./exportUtils";
import axios from 'axios';
import CommonModal from 'components/CommonModal/CommonModal';
import InputLabel from 'components/Label/InputLabel';

interface CurrentState {
    apps: AppsState,
    export: ExportState,
    exportDates: ExportDatesState,
    languageData: LanguageState,
    exportRequestSource: ExportRequestSourceFilterState,
    eventSource: EventSourceState,
}

const ExportList = (props: any) => {
    const isInitialMount = useRef(true);

    const { showBoundary } = useErrorBoundary();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [insightsLbls, setInsightsLbls] = useState<InsightLabels>({} as InsightLabels)
    const componentRef = useRef() as React.RefObject<HTMLDivElement>;
    const dateZone: any = new Date().toString();
    let fetched_details = useSelector((state: CurrentState) => state);
    const timeZone: string = dateZone.match(/([A-Z]+[+-][0-9]+)/)[1];

    let calendarOptions: any = [
        { dateSelection: 3, text: insightsLbls.last7Days },
        { dateSelection: 10, text: insightsLbls.last30Days },
        { dateSelection: 16, text: insightsLbls.last60Days },
        { dateSelection: 11, text: insightsLbls.last90Days },
        { dateSelection: 12, text: insightsLbls.last180Days },
        { dateSelection: 13, text: insightsLbls.last365Days },
        { dateSelection: 14, text: insightsLbls.lastCalendarYear },
        { dateSelection: 17, text: insightsLbls.ytdCalendarYear },
        { dateSelection: 15, text: insightsLbls.customDate },
    ];

    const getDateIn15MinGap = () => {
        let date = new Date();
        date.setMinutes(date.getMinutes() + 5 - (date.getMinutes() % 5));
        date.setSeconds(0);
        return date.getTime();
    }

    const parseDateString = (dateString) => {
        const [datePart, timePart] = dateString.split('_');
        const [year, month, day] = datePart.split('-').map(Number);
        const [hours, minutes, seconds] = timePart.split(':').map(Number);
        return new Date(year, month - 1, day, hours, minutes, seconds).getTime();
    }

    /** States */
    const [pageTab, setPageTab] = useState({
        key: "exJobList"
    })

    const [isLoading, setIsLoading] = useState(false);

    const [exportList, setExportList] = useState({
        data: [],
        listDataFetched: false,
        apiError: 0,
        firstLoad: true,
    })

    const [templateList, setTemplateList] = useState({
        data: [],
        listDataFetched: false,
        apiError: 0,
        firstLoad: true,
    })

    const [schedulerList, setSchedulerList] = useState({
        data: [],
        listDataFetched: false,
        apiError: 0,
        firstLoad: true,
    })

    const [newJobData, setNewJobData] = useState({
        "app_ids": [],
        "guide_ids": [],
        "tooltip_ids": [],
        "guide_type": ['guide', 'tooltip'],
        "guide_type_breakdown": [],
        "event_source": ['player']
    })

    const [templateData, setTemplateData] = useState({
        "template_id": "",
        "template_title": "",
        "app_ids": [],
        "guide_ids": [],
        "tooltip_ids": [],
        "guide_type": ['guide', 'tooltip'],
        "guide_type_breakdown": [],
        "event_source": ['player']
    })

    const [scheduleData, setScheduleData] = useState({
        "schedule_id": "",
        "schedule_title": "",
        "schedule": {
            "type": "one-time",
            "trigger_time": getDateIn15MinGap(),
            "sub_type": "day-of-week",
            "day_selection": ["mon"],
            "time_of_day": "18:00",
        },
        "app_ids": [],
        "guide_ids": [],
        "tooltip_ids": [],
        "guide_type": ['guide', 'tooltip'],
        "guide_type_breakdown": [],
        "event_source": ['player'],
        "export_timeframe_in_days": "7",
        "start_timestamp": new Date(new Date().getTime() - (7 * 24 * 60 * 60 * 1000)).getTime(),
        "end_timestamp": new Date().getTime(),
        "schedule_status": "inactive",
        "email_cc": [],
        "selected_id": '',
        "emailLoadFlag": true
    })



    const [emailIds, setEmailIds] = useState({
        ids: [],
        valid: true
    })


    const [exportBtnDisabled, setExportBtnDisabled] = useState({
        exportBtnDisabled: false,
    })

    const [arrowIndex, setArrowIndex] = useState({
        index_up: null
    });

    const updateTemplateTitle = (value) => {
        setTemplateData((prevState) => {
            return {
                ...prevState,
                template_title: value
            }
        })
    }

    const updateScheduleTitle = (value) => {
        setScheduleData((prevState) => {
            return {
                ...prevState,
                schedule_title: value
            }
        })
    }
    /** States */

    /** App Dropdown part started */

    //State to track if application data is fetched or not
    const [appsFetched, setAppsFetched] = useState({
        data: [],
        isFetched: false,
        error: true
    })

    //State to set selected app data from export utils
    const [selectedAppData, setSelectedAppData] = useState([]);

    //short hand set app fetched property to avoid redundant code
    const setAppsFetchedShortHand = (data, isFetched, isError) => {
        setAppsFetched((prevState) => {
            return {
                ...prevState,
                data: data,
                isFetched: isFetched,
                isError: isError
            }
        })
    }

    //Handler to set selected app from export utils
    const setSelectedAppDataHandler = appList => setSelectedAppData(appList);

    //Api call to get app data and set app fetched
    const getAppList = (apiErrorFlag?: string) => {
        try {
            let exportAppList: any = localStorage.getItem('EXPORT_APP_LIST');
            if (exportAppList && exportAppList != null) {
                setAppsFetchedShortHand(JSON.parse(exportAppList), true, false);
            } else {
                let cancelToken: any;
                if (typeof cancelToken !== typeof undefined) {
                    cancelToken.cancel(insightsLbls.tokenCancelledMsg);
                }
                cancelToken = axios.CancelToken.source();
                let params = {
                    "type": 'org',
                    "sort_by": "app_name",
                    "order": "asc",
                    "search_text": '',
                    "page_index": 1,
                    "page_size": 9999,
                    "hide_deleted": 0
                };
                getCall(params, 'APP_LIST', apiErrorFlag ?? '').then((data: any) => {
                    if (data?.result === 'success') {
                        if (data && data.data && data.data.apps && data.data.apps.length > 0) {
                            let appData = data.data.apps.map(app => ({ "appId": app.app_id, "appName": app.app_name }))
                            setAppsFetchedShortHand(appData, true, false);
                        } else {
                            setAppsFetchedShortHand([], true, true);
                        }
                    } else if (data.result === 'retry') {
                        setTimeout(() => {
                            getAppList('retry')
                        }, RetryApi.TIMEOUT)
                    }
                    else if (data?.result === "error") {
                        setAppsFetchedShortHand([], true, true);
                    }
                });
            }
        } catch (error) {
            showBoundary(error)
        }
    }

    //Get App name by app id
    const getAppName = (appId) => appsFetched.data.find(app => app.appId === appId)?.appName;

    //Get App chip by app id
    const getAppChip = (appId) => <span className="infoChip appChips">{getAppName(appId)}</span>

    //Return JSX to show selected app chips
    const getAppsSelectionData = () => {
        return <div className={selectedAppData.length == 1 ? "row-flex" : ""}>
            <div className={selectedAppData.length == 1 ? "exportRequestFilterListTitleDiv width-fit" : "exportRequestFilterListTitleDiv"}>
                {insightsLbls.selectedApps}:
            </div>
            <div className="exportRequestAppsListDiv">
                <div className="exportRequestAppNameDiv">
                    <p className="font14 chipParagraph">
                        {
                            appsFetched.isFetched ?
                                (selectedAppData.length == 0 ? appsFetched.data.map(app => getAppChip(app.appId)) : selectedAppData.map(appId => getAppChip(appId))) :
                                <Loader width={100}></Loader>
                        }
                    </p>
                </div>
            </div>
        </div>
    }

    //Return JSX to show app names in export job/template/scheduler list
    const appRequestedData = (appIdList: []) => {
        try {
            if (appsFetched.data.length == 0) return;
            let processData = appIdList.length == 0 ? appsFetched.data.map(app => app.appId) : appIdList;
            let finalData = processData.map(appId => getAppName(appId));
            let totalAppsLength = finalData.length;
            if (totalAppsLength > 3) {
                return <b className="font14 textCapitalize">{finalData.slice(0, 2).join(', ') + '...'}
                    <span className="moreChip">{totalAppsLength - 2} more</span></b>
            } else {
                return <b className="font14 textCapitalize">{finalData.join(', ')}</b>
            }
        } catch (error) {
            showBoundary(error)
        }
    }

    /** App Dropdown part ended */


    /** EventSource Dropdown part started */

    let eventSourcesList = [
        { key: 'creator', value: insightsLbls.webCreator },
        { key: 'player', value: insightsLbls.webPlayer },
        { key: 'desktop_player', value: insightsLbls.desktopPlayer },
        { key: 'desktop_creator', value: insightsLbls.desktopCreator },
        { key: 'mobile_creator', value: insightsLbls.mobileCreator },
        { key: 'mobile_player', value: insightsLbls.mobilePlayer },
    ];

    //State to set selected event source data from export utils
    const [selectedEventSourceData, setSelectedEventSourceData] = useState([]);

    //Handler to set selected event source data from export utils
    const setSelectedEventSourceDataHandler = eventSourceList => setSelectedEventSourceData(eventSourceList);

    //Get event source name by key
    const getEventSourceValue = (esKey) => eventSourcesList.find(es => es.key === esKey)?.value;

    //Get event source chip by key
    const getEventSourceChip = (esKey) => <span className="infoChip appChips">{getEventSourceValue(esKey)}</span>

    const getEventSourceCard = (selectedEvents) => {
        return <div className="exportRequestEventSourceDiv">
            <div className="exportRequestCardDiv">
                <div className="exportRequestFilterDiv eventDiv positionRelative">
                    {
                        <>
                            {pageTab.key !== 'exJobList' && (
                                <div className="playTypeSelectionTitle positionAbsolute left20 font600 font16">
                                    {insightsLbls.eventSource}
                                </div>
                            )}
                            
                            <GetEventSourceDropdown eventSourceList={eventSourcesList} insightsLbls={insightsLbls}
                                selectedEvents={selectedEvents} selectedEventSourceData={setSelectedEventSourceDataHandler}></GetEventSourceDropdown>

                        </>
                    }
                </div>
                <div className="exportRequestFilterInformationDiv">
                    <div className="exportRequestFilterListTitleDiv">
                        {insightsLbls.selecetdEventSource}:
                    </div>
                    <div className="exportRequestEventSourceListDiv">
                        <div className="exportRequestEventSourceNameDiv">
                            <p className="font14 chipParagraph ">
                                {
                                    selectedEventSourceData.length == 0
                                        ? eventSourcesList.map(es => getEventSourceChip(es.key))
                                        : selectedEventSourceData.map(esKey => getEventSourceChip(esKey))
                                }
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    }

    /** Event Source dropdown part ended */


    /** Data Source Dropdown part started */

    let dataSourcesList = [
        { key: 'guide', value: insightsLbls.guidesTab },
        { key: 'tooltip', value: insightsLbls.tooltipLabel },
        { key: 'search', value: insightsLbls.searches },
    ];

    //State to set selected data source data from export utils
    const [selectedDataSourceData, setSelectedDataSourceData] = useState([]);

    //Handler to set selected data source data from export utils
    const setSelectedDataSourceDataHandler = dataSourcesList => setSelectedDataSourceData(dataSourcesList);

    //Get data source name by key
    const getDataSourceValue = (dsKey) => dataSourcesList.find(ds => ds.key === dsKey)?.value;

    //Get data source chip by key
    const getDataSourceChip = (dsKey) => <span className="infoChip appChips">{getDataSourceValue(dsKey)}</span>

    const getDataSourceCard = (selectedDataSource) => {
        return <div className=" col-12 col-sm-12 col-md-12 col-lg-12">
            <div className="displayFlex col-12 col-sm-12 col-md-12 col-lg-12 paddingLeftZero">
                {(selectedDataSourceData.length === 0 ? selectedDataSource.includes('tooltip') : selectedDataSourceData.includes('tooltip')) &&
                    (<div className="exportRequestFilterListTitleDiv warningMsg-text tooltipexport-warn paddingLeftZero">
                        {insightsLbls.noteAboutExclusion}
                    </div>)
                }
            </div>
            <div className="displayFlex col-12 col-sm-12 col-md-12 col-lg-12 paddingLeftZero">
                <div className=" col-6 col-sm-6 col-md-6 col-lg-6 playTypeSelectionContainer paddingLeftZero">
                    <div className="playTypeSelectionTitle">
                        {insightsLbls.selectedDataSources}
                    </div>
                    <GetDataSourceDropdown dataSourcesList={dataSourcesList} insightsLbls={insightsLbls}
                        selectedDataSource={selectedDataSource} selectedDataSourceData={setSelectedDataSourceDataHandler}></GetDataSourceDropdown>

                </div>
                <div className=" col-6 col-sm-6 col-md-6 col-lg-6">
                    <div className="exportRequestFilterInformationDiv row-flex">
                        <div className='appPlayGranularSelectionDiv'>
                            <div className="exportRequestFilterListTitleDiv">
                                {insightsLbls.selectedDataSources}:
                            </div>
                            <div className="exportRequestDataSourceListDiv">
                                <div className="exportRequestDataSourceNameDiv">
                                    <p className="font14 chipParagraph">
                                        {selectedDataSourceData.length == 0
                                            ? dataSourcesList.map(ds => getDataSourceChip(ds.key)) :
                                            selectedDataSourceData.map(dataSourceKey => getDataSourceChip(dataSourceKey))}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    }

    /** Data Source dropdown part ended */


    /** Play Type  Dropdown part started */

    let playTypeList = [
        { key: 'guideme', value: insightsLbls.guideMe },
        { key: 'showme', value: insightsLbls.showMe },
        { key: 'testme', value: insightsLbls.testMe },
        { key: 'doitforme', value: insightsLbls.doItForMe },
        { key: 'tutorial', value: insightsLbls.tutorial }
    ]

    //State to set selected play type data from export utils
    const [selectedPlayTypeData, setSelectedPlayTypeData] = useState([]);

    //Handler to set selected play type data from export utils
    const setSelectedPlayTypeDataHandler = playTypeList => setSelectedPlayTypeData(playTypeList);

    //Get play type name by key
    const getPlayTypeValue = (playKey) => playTypeList.find(play => play.key === playKey)?.value;

    //Get play type chip by key
    const getPlayTypeChip = (playKey) => <span className="infoChip appChips">{getPlayTypeValue(playKey)}</span>

    const getPlayTypeCard = (selectedPlayType) => {
        return <div className="displayFlex col-12 col-sm-12 col-md-12 col-lg-12">
            <div className=" col-6 col-sm-6 col-md-6 col-lg-6 paddingLeftZero">
                <div className="playTypeSelectionContainer paddingLeftZero">
                    <div className="playTypeSelectionTitle">
                        {insightsLbls.selectPlayType}
                    </div>
                    <div className="playTypeSelector">{
                        <GetPlayTypeDropdown insightsLbls={insightsLbls} playTypesList={playTypeList}
                            selectedPlayType={selectedPlayType} selectedPlayTypeData={setSelectedPlayTypeDataHandler}></GetPlayTypeDropdown>
                    }</div>
                </div>
            </div>
            <div className=" col-6 col-sm-6 col-md-6 col-lg-6">
                <div className="exportRequestFilterListTitleDiv">
                    {insightsLbls.selectedPlayTypes}:
                </div>
                <div className="exportRequestAppsListDiv">
                    <div className="exportRequestAppNameDiv">
                        <p className="font14 chipParagraph">
                            {
                                selectedPlayTypeData.length == 0
                                    ? playTypeList.map(pt => getPlayTypeChip(pt.key))
                                    : selectedPlayTypeData.map(playKey => getPlayTypeChip(playKey))
                            }
                        </p>
                    </div>
                </div>
            </div>
        </div>
    }

    /** Play Type dropdown part ended */


    /** Guide Tooltip dropdown part started */

    const [guideData, setGuideData] = useState({
        isLoading: false,
        isError: false,
        data: [],
        lastAppID: ""
    })

    const [tooltipData, setTooltipData] = useState({
        isLoading: false,
        isError: false,
        data: [],
        lastAppID: ""
    })

    const getGuideTooltipList = (appId, isGuide) => {
        return new Promise((resolve, reject) => {
            const params = {
                'time_zone': timeZone,
                'app_id': appId
            };
            exportActions.getGuideTutorialListData(
                params,
                isGuide ? "GET_GUIDES_BY_APP" : "GET_TOOLTIPS_BY_APP"
            ).then((res: any) => resolve(res));
        })
    }

    //State to set selected guide type data from export utils
    const [selectedGuideData, setSelectedGuideData] = useState([]);

    //Handler to set selected guide type data from export utils
    const setSelectedGuideDataHandler = guideList => setSelectedGuideData(guideList);

    const getGuideDropdown = (selectedGuide) => {
        return (
            guideData.isLoading ? <Loader></Loader> :
                (guideData.data.length == 0 ? <div className="noData-div">{insightsLbls.noRecordFound}</div> :
                    <GetGuideDropdown guideList={guideData.data} insightsLbls={insightsLbls} selectedGuide={selectedGuide}
                        selectedGuideData={setSelectedGuideDataHandler}></GetGuideDropdown>
                )
        )
    }

    //State to set selected tooltip type data from export utils
    const [selectedTooltipData, setSelectedTooltipData] = useState([]);

    //Handler to set selected tooltip type data from export utils
    const setSelectedTooltipDataHandler = tooltipList => setSelectedTooltipData(tooltipList);

    const getTooltipDropdown = (selectedTooltip) => {
        return (
            tooltipData.isLoading ? <Loader></Loader> :
                (tooltipData.data.length == 0 ? <div className="noData-div">{insightsLbls.noRecordFound}</div> :
                    <GetTooltipDropdown tooltipList={tooltipData.data} insightsLbls={insightsLbls} selectedTooltip={selectedTooltip}
                        selectedTooltipData={setSelectedTooltipDataHandler}></GetTooltipDropdown>
                )
        )
    }

    const getGuideTutorialSelectionContainer = (selectedGuideData, selectedTooltipData) => {
        return (<div className="guideTooltipSelectionContainer">
            {selectedDataSourceData.includes("guide") && <div className="guideSelectionContainer">
                <div className="selectionTitle">
                    {insightsLbls.selectGuide}:
                </div>
                <div className="guideSelector">{getGuideDropdown(selectedGuideData)}</div>
            </div>}
            {selectedDataSourceData.includes("tooltip") && <div className="tooltipSelectionContainer">
                <div className="selectionTitle">
                    {insightsLbls.selectedTooltip}:
                </div>
                <div className="tooltipSelector">{getTooltipDropdown(selectedTooltipData)}</div>
            </div>}
        </div>)
    }

    /** Guide Tooltip dropdown part ended */


    /** Schedule Frequency dropdown part started */

    let frequencyList = [{ key: "one-time", value: insightsLbls.oneTime }, { key: "recurring", value: insightsLbls.repetitive }]

    const [frequencyDropdown, setFrequencyDropdown] = useState(false);

    const updateFrequencyState = (value) => {
        setScheduleData((prevState) => {
            return {
                ...prevState,
                schedule: {
                    ...scheduleData.schedule,
                    type: value,
                    sub_type: "day-of-week",
                    day_selection: ["mon"]
                }
            }
        });
        setFrequencyDropdown(false);
    }

    const getFilterValue = (key) => frequencyList.find(freq => freq.key == key)?.value;

    const getFrequencyDropdown = () => {
        return (
            <>
                <Dropdown className="frequency-dropdown" show={frequencyDropdown} onToggle={(event) => setFrequencyDropdown(event)}>
                    {insightsLbls && (
                        <Dropdown.Toggle disabled={scheduleData.schedule_id != ""} className={"btn btn-lg btn-outline-primar positionAbsolute bootstrapDCWorkaround2 source-but-frequency buttonPositionStateFilter"} style={{ zIndex: "1" }}>
                            <span>{getFilterValue(scheduleData.schedule.type)}</span>
                            <i aria-hidden="true" className={"switchApp-btn-icon " + (frequencyDropdown ? "fa fa-chevron-up" : "fa fa-chevron-down")}></i>
                        </Dropdown.Toggle>
                    )}

                    <Dropdown.Menu className={"dropdown-menu source-menu-frequency"}>
                        {
                            frequencyList.map((freq: any, index: number) => {
                                return (
                                    <div className="dropdown-status-menu" key={index}>
                                        <button onClick={() => updateFrequencyState(freq.key)} id={"freq_" + freq.key}
                                            className={"pointer dropdown-menu-item statusBtn " + (scheduleData.schedule.type === freq.key ? "active-button" : "")}>
                                            {freq.value}
                                        </button>
                                    </div>
                                );
                            })
                        }
                    </Dropdown.Menu>
                </Dropdown>
            </>
        )
    }

    /** Schedule Frequency dropdown part ended */


    /** Schedule Frequency subtype dropdown part started */

    let freqSubTypeList = [{ key: "day-of-week", value: "Day of Week" }, { key: "date-of-month", value: "Date of Month" }]

    const [freqSubTypeDropdown, setFreqSubTypeDropdown] = useState(false);

    const updateFrequencySubTypeState = (value) => {
        setScheduleData((prevState) => {
            return {
                ...prevState,
                schedule: {
                    ...scheduleData.schedule,
                    sub_type: value,
                    day_selection: value === "day-of-week" ? ["mon"] : ["1"]
                }
            }
        });
        setFreqSubTypeDropdown(false);
    }

    const getFilterValueForSubType = (key) => freqSubTypeList.find(freq => freq.key == key)?.value;

    const getFrequencySubTypeDropdown = () => {
        return (
            <>
                <Dropdown className="frequency-dropdown" show={freqSubTypeDropdown} onToggle={(event) => setFreqSubTypeDropdown(event)}>
                    {insightsLbls && (
                        <Dropdown.Toggle disabled={scheduleData.schedule_id != ""} className={"btn btn-lg btn-outline-primar positionAbsolute bootstrapDCWorkaround2 source-but-frequency buttonPositionStateFilter"}>
                            <span>{getFilterValueForSubType(scheduleData.schedule.sub_type)}</span>
                            <i aria-hidden="true" className={"switchApp-btn-icon " + (freqSubTypeDropdown ? "fa fa-chevron-up" : "fa fa-chevron-down")}></i>
                        </Dropdown.Toggle>
                    )}

                    <Dropdown.Menu className={"dropdown-menu source-menu-frequency"}>
                        {
                            freqSubTypeList.map((freqSubType: any, index: number) => {
                                return (
                                    <div className="dropdown-status-menu" key={index}>
                                        <button onClick={() => updateFrequencySubTypeState(freqSubType.key)} id={"freq_type_" + freqSubType.key}
                                            className={"pointer dropdown-menu-item statusBtn " + (scheduleData.schedule.sub_type === freqSubType.key ? "active-button" : "")}>
                                            {freqSubType.value}
                                        </button>
                                    </div>
                                );
                            })
                        }
                    </Dropdown.Menu>
                </Dropdown>
            </>
        )
    }

    /** Schedule Frequency subtype dropdown part ended */


    /** Day selection dropdown part started */

    let dayOfWeekList = [
        { key: "mon", value: insightsLbls.monday },
        { key: "tue", value: insightsLbls.tuesday },
        { key: "wed", value: insightsLbls.wednesday },
        { key: "thu", value: insightsLbls.thursday },
        { key: "fri", value: insightsLbls.friday },
        { key: "sat", value: insightsLbls.saturday },
        { key: "sun", value: insightsLbls.sunday },
    ]

    let dateOfMonthList = Array.from({ length: 31 }, (_, index) => ({ key: (index + 1).toString(), value: (index + 1).toString() }));

    const [daySelectionDropdown, setDaySelectionDropdown] = useState(false);


    const handleDaySelect = (key, event) => {
        let tempState = [...scheduleData.schedule.day_selection]
        if (event.target.checked) {
            if (!tempState.includes(key)) {
                tempState.push(key);
            }
            if (tempState.length > 7) {
                tempState = tempState.slice(-7);
            }
        } else {
            if (tempState.includes(key)) {
                tempState = tempState.filter(s => s != key);
            }
            if (tempState.length == 0) {
                tempState.push(scheduleData.schedule.sub_type == "day-of-week" ? dayOfWeekList[0].key : dateOfMonthList[0].key);
            }
        }
        setScheduleData((prevState) => {
            return {
                ...prevState,
                schedule: {
                    ...scheduleData.schedule,
                    day_selection: tempState
                }
            }
        })
    }

    const getSelectedDayLabel = () => {
        if (scheduleData.schedule.sub_type == "day-of-week") {
            return dayOfWeekList.find(day => scheduleData.schedule.day_selection.includes(day.key))?.value;
        } else {
            return dateOfMonthList.find(day => scheduleData.schedule.day_selection.includes(day.key))?.value;
        }
    }

    const getMappingList = () => {
        return scheduleData.schedule.sub_type == "day-of-week" ? dayOfWeekList : dateOfMonthList;
    }

    const getDaySelectionDropdown = () => {
        return (
            <>
                <Dropdown show={daySelectionDropdown} onToggle={(event) => setDaySelectionDropdown(event)}>
                    <Dropdown.Toggle className={"btn btn-lg btn-outline-primar day-selection-dropdown positionAbsolute bootstrapDCWorkaround2 buttonPositionEventSource"} style={{ zIndex: 1 }}>
                        {scheduleData.schedule.day_selection.length > 1 && (<span className="playType-count">+{scheduleData.schedule.day_selection.length - 1}</span>)}
                        <span className="playType-label">{getSelectedDayLabel()}</span>
                        <i aria-hidden="true" className={"switchApp-btn-icon " + (daySelectionDropdown ? "fa fa-chevron-up" : "fa fa-chevron-down")}></i>
                    </Dropdown.Toggle>
                    <Dropdown.Menu className={"dropdown-menu source-menu-day-of-week "} style={{ maxHeight: "300px", overflow: "auto" }}>
                        {getMappingList().map((elem: any, index: number) => {
                            return (
                                <div key={index} className="custom-control custom-checkbox mr-sm-2 displayFlex alignCenter  marginLeft-20 marginTop-10 marginBottom-10 check-container padding-chk-0">
                                    <div className="custom-control custom-checkbox mr-sm-2 check-container padding-chk-0 pointer">
                                        <label className="check-container displayFlex alignCenter exportCheck marginBottom-0 marginLeft--5">
                                            <span className="marginLeft-25">{elem.value}</span>
                                            <input type="checkbox" className={"custom-control-input chk checkbox"}
                                                id="customControlAutosizing" name={elem.value}
                                                value={elem.key} onChange={(event) => handleDaySelect(elem.key, event)} checked={scheduleData.schedule.day_selection.includes(elem.key)}></input>
                                            <span className="checkmark positionAbsolute checkmarkBox-color displayInlineBlock top0"></span>
                                        </label>
                                    </div>
                                </div>
                            );
                        })
                        }
                    </Dropdown.Menu>
                </Dropdown>
            </>
        )
    }

    /** Day select dropdown part ended */

    /** Time dropdown part started */

    const [isOpenTimePicker, setIsOpenTimePicker] = useState(false);

    const handleTimeChange = (time) => {
        let formattedTime = null;
        if (time) {
            formattedTime = `${time.getHours().toString().padStart(2, '0')}:${time.getMinutes().toString().padStart(2, '0')}`;
        }

        setScheduleData((prevState) => {
            return {
                ...prevState,
                schedule: {
                    ...scheduleData.schedule,
                    time_of_day: formattedTime
                }
            }
        })
    };

    const CustomInput = ({ }) => (
        <input
            type="text"
            value={scheduleData.schedule.time_of_day}
            onClick={() => setIsOpenTimePicker(!isOpenTimePicker)}
            placeholder={insightsLbls.selectTime}
            readOnly
        />
    );

    const getTimeDropdown = () => {
        return <DatePicker
            selected={null}
            onChange={handleTimeChange}
            showTimeSelect
            showTimeSelectOnly
            timeIntervals={15}
            timeCaption="Time"
            timeFormat="HH:mm"
            customInput={<CustomInput />}
            open={isOpenTimePicker}
            onClickOutside={() => setIsOpenTimePicker(false)} //
        />
    }

    /** Time dropdown part ended */

    /** Date Time dropdown started */

    const handleDateTimeChange = (datetime) => {
        setScheduleData((prevState) => {
            return {
                ...prevState,
                schedule: {
                    ...scheduleData.schedule,
                    trigger_time: datetime
                }
            }
        })
    };

    const getDateTimeDropdown = () => {
        return <DatePicker
            selected={new Date(scheduleData.schedule.trigger_time)}
            onChange={(dateVal) => { handleDateTimeChange(dateVal.getTime()) }}
            showTimeSelect
            dateFormat="MMMM d, yyyy HH:mm"
            minDate={new Date()}
            timeIntervals={15}
            timeFormat="HH:mm"
            dropdownMode="select"
        />
    }

    /** Date Time dropdown ended */

    /** Recurring date picker card started */

    let recurringDatePickerList = [
        { key: "7", value: insightsLbls.last7Days },
        { key: "30", value: insightsLbls.last30Days },
        { key: "60", value: insightsLbls.last60Days },
        { key: "90", value: insightsLbls.last90Days },
        { key: "180", value: insightsLbls.last180Days },
        { key: "365", value: insightsLbls.last365Days }
    ]

    const [recurringDatePickerState, setRecurringDatePickerState] = useState(false)

    const updateRecurringDatePickerState = (value) => {
        setScheduleData((prevState) => {
            return {
                ...prevState,
                export_timeframe_in_days: value
            }
        });
        setRecurringDatePickerState(false);
    }

    const getFilterValueForRecurringDatePicker = (key) => recurringDatePickerList.find(picker => picker.key == key)?.value;

    const getRecurringDatePickerCard = (optn) => {
        return <div className="exportRequestDatePickerDiv">
            <div className="exportRequestCardDiv">
                <div className="exportRequestFilterDiv dateDiv">
                    <div className="datepiecker-div floatRight datepiecker-div-modal">
                        <Dropdown show={recurringDatePickerState} onToggle={(event) => setRecurringDatePickerState(event)}>
                            {insightsLbls && (
                                <Dropdown.Toggle>
                                    <div className="displayFlex alignCenter">
                                        <i className="fa fa-calendar source-icon" aria-hidden="true"></i>
                                        <div className="width100">
                                            <input
                                                type="text"
                                                autoComplete="off"
                                                className="paddingTopBottom date-input 1"
                                                value={getFilterValueForRecurringDatePicker(scheduleData.export_timeframe_in_days)}
                                                readOnly
                                            />
                                        </div>
                                        <i aria-hidden="true" className={"dropdown-toggle switchApp-btn-icon " + (recurringDatePickerState ? "fa fa-chevron-up" : "fa fa-chevron-down")}></i>
                                    </div>
                                </Dropdown.Toggle>
                            )}

                            <Dropdown.Menu className={"marginTop-42"}>
                                <div>
                                    <ul className="padding0 marginBottom-15">
                                        {
                                            recurringDatePickerList.map((picker: any, index: number) => {
                                                return (
                                                    <div className="displayFlex calenderOptionspadding alignCenter" key={index}>
                                                        <ToggleButtonGroup
                                                            className="datepickerRadioButtons"
                                                            name="dateOptions"
                                                            type="radio"
                                                            value={picker.key}
                                                            onClick={() => updateRecurringDatePickerState(picker.key)}
                                                        >
                                                            <ToggleButton
                                                                id={"tbtn_" + index}
                                                                style={{ cursor: 'pointer' }}
                                                                className="datepickerRadio"
                                                                value={picker.key}>
                                                                {scheduleData.export_timeframe_in_days === picker.key ?
                                                                    (<span className="dot">&nbsp;</span>) : (null)}
                                                            </ToggleButton>
                                                        </ToggleButtonGroup>
                                                        <span className={"datepicker-optn " + (scheduleData.export_timeframe_in_days === picker.key
                                                            ? "font700" : "")}>
                                                            {picker.value}
                                                        </span>
                                                    </div>
                                                );
                                            })
                                        }
                                    </ul>
                                </div>
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                </div>
                <div className="exportRequestFilterInformationDiv">
                    <div className="exportRequestFilterListTitleDiv">
                        {insightsLbls.selectedDateRange}:
                    </div>
                    {
                        <div className="exportRequestDateListDiv">
                            {
                                recurringDatePickerList.map((data: any, key: number) => {
                                    return (
                                        scheduleData.export_timeframe_in_days === data.key ?
                                            (
                                                <>
                                                    <p className="font14 chipParagraph">
                                                        <span className="infoChip dateChips">{data.value}</span>
                                                    </p>
                                                </>
                                            )
                                            :
                                            (null)
                                    );
                                })
                            }
                        </div>
                    }
                </div>
            </div>
        </div>
    }

    /** Recurring data picker card ended */

    /** Schedule start end date selector started */
    var d = new Date();
    d.setDate(d.getDate() - 6);
    const [scheduleDataStartDate, setScheduleDataStartDate] = useState<Date | Number>(d);
    const [scheduleDataEndDate, setScheduleDataEndDate] = useState<Date | Number>(new Date());



    const [isOpen, setIsOpen] = useState(false);

    const handleDateChange = (dates) => {
        const [start, end] = dates;

        console.log(start);
        setScheduleDataStartDate(start);
        setScheduleDataEndDate(end);

        if(start && end) {
            setIsOpen(false)
        }
    };

    const toggleDatepicker = () => {
        if (isOpen) {
            let startTime = scheduleData.start_timestamp;
            let endTime = scheduleData.end_timestamp;
            let isChangeReq = false;
            if (endTime == null) {
                endTime = scheduleData.start_timestamp + (7 * 24 * 60 * 60 * 1000);
                if (endTime > scheduleData.schedule.trigger_time) {
                    endTime = scheduleData.schedule.trigger_time
                }
                isChangeReq = true;
            }
            const currentDate = new Date(scheduleData.schedule.trigger_time);
            const oneYearAgo = new Date(currentDate.getFullYear() - 1, currentDate.getMonth(), currentDate.getDate());
            if (startTime < oneYearAgo.getTime()) {
                startTime = oneYearAgo.getTime();
                isChangeReq = true
            }
            if (isChangeReq) {
                setScheduleData(prevState => {
                    return {
                        ...prevState,
                        start_timestamp: startTime,
                        end_timestamp: endTime
                    }
                })

                console.log(new Date(startTime));
                setScheduleDataEndDate(new Date(endTime))
                setScheduleDataStartDate(new Date(startTime))

            }
        }
        setIsOpen(!isOpen);
    }

    const getDateInString = (dt, isHyphenReq = true) => {

        // let dateString = dt.toString();
        // let date;
        // if(dateString.includes('_00')) {
        //     dateString = dt;
        //     dateString = dateString.replace("_", " ");
        //      date = new Date(dateString);
        // } else {
        //     date = dt;
        // }



        if (dt == null) return;
        let tempDate = new Date(dt);
        const day = tempDate.getDate().toString().padStart(2, '0');
        const monthShortName = tempDate.toLocaleString('default', { month: 'short' });
        const yearShort = String(tempDate.getFullYear()).slice(-2)
        let separator = isHyphenReq ? "-" : " ";

        return day + separator + monthShortName + separator + yearShort;
    }

    const getFilterValueForStartEndDatePicker = (startDt, endDt) => {
            if(startDt && endDt) {
                return getDateInString(startDt, false) + " to " + getDateInString(endDt, false);
            }
    }

    const getStartEndDatePickerCard = () => {
        let dt = new Date(scheduleData.schedule.trigger_time);
        let minDate = new Date(dt.getFullYear() - 2, dt.getMonth(), dt.getDate());
        return <div className="exportRequestDatePickerDiv">
            <div className="exportRequestCardDiv">
                <div className="exportRequestFilterDiv dateDiv">
                    <div className="datepiecker-div floatRight datepiecker-div-modal">

                        <Dropdown show={isOpen} onToggle={(event) => toggleDatepicker()}>
                            {insightsLbls && (
                                <Dropdown.Toggle>
                                    <div className="displayFlex alignCenter">
                                        <i className="fa fa-calendar source-icon" aria-hidden="true"></i>
                                        <div className="width100">
                                            <input
                                                type="text"
                                                autoComplete="off"
                                                className="paddingTopBottom date-input 2"
                                                value={getFilterValueForStartEndDatePicker(scheduleDataStartDate, scheduleDataEndDate)}
                                                readOnly
                                            />
                                        </div>
                                        <i aria-hidden="true" className={"dropdown-toggle switchApp-btn-icon " + (isOpen ? "fa fa-chevron-up" : "fa fa-chevron-down")}></i>
                                    </div>
                                </Dropdown.Toggle>
                            )}

                            <Dropdown.Menu className={"marginTop-42 startEndMenuClass"}>
                                <div>

                                <DatePicker
                                    selected={scheduleDataStartDate}
                                    onChange={handleDateChange}
                                    startDate={scheduleDataStartDate}
                                    endDate={scheduleDataEndDate}
                                    selectsRange
                                    selectsDisabledDaysInRange
                                    inline
                                    maxDate={new Date(scheduleData.schedule.trigger_time)}
                                    showPopper
                                />
                                    {/* <DatePicker
                                        onChange={handleDateChange}
                                        startDate={scheduleData.start_timestamp}
                                        endDate={scheduleData.end_timestamp}
                                        maxDate={new Date(scheduleData.schedule.trigger_time)}
                                        minDate={minDate}
                                        monthsShown={1}
                                        selectsRange
                                        inline
                                    /> */}

                                    
                                </div>
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                </div>
                <div className="exportRequestFilterInformationDiv">
                    <div className="exportRequestFilterListTitleDiv">
                        {insightsLbls.selectedDateRange}:
                    </div>
                    <div className="exportRequestDateListDiv"><p className="font14 chipParagraph">
                        <span className="infoChip dateChips">{getDateInString(scheduleDataStartDate)} to {getDateInString(scheduleDataEndDate)}</span>
                    </p>
                    </div>
                </div>
            </div>
        </div>
    }
    /** Schedule start end date selector ended */


    const fdate: string = (fetched_details.exportDates.export_fdate)!
    const tdate: string = (fetched_details.exportDates.export_tdate)!

    /** fetching list for export/template/scheduler */

    /** Utility methods for list start */
    const changeTab = (e: any) => {
        try {
            if (e === "exTemList") {
                if (!templateList.listDataFetched) {
                    getExportTemplateCall();
                }
            }
            if (e === "exSchList") {
                if (!schedulerList.listDataFetched) {
                    getExportSchedulerListCall();
                }
            }
            setPageTab((prevState: any) => {
                return {
                    ...prevState,
                    key: e
                };
            });
            setArrowIndex((prevState) => {
                return {
                    ...prevState,
                    index_up: null
                }
            })
        } catch (error) {
            showBoundary(error)
        }
    };

    const modifiedData = (data: [], type: string) => {
        try {
            let finalData: any;
            let processData;
            if (type === 'datasource') {
                processData = data.length == 0 ? dataSourcesList.map(ds => ds.key) : data;
                finalData = processData.map((source) => getDataSourceValue(source));
            } else if (type === 'eventsource') {
                processData = data.length == 0 ? eventSourcesList.map(es => es.key) : data;
                finalData = processData.map((event) => getEventSourceValue(event));
            }
            if (finalData.length > 3) {
                return <b className="font14 textCapitalize">{finalData.slice(0, 2).join(', ') + '...'}
                    <span className="moreChip">{finalData.length - 2} more</span></b>
            } else {
                return <b className="font14 textCapitalize">{finalData.join(', ')}</b>
            }
        } catch (error) {
            showBoundary(error)
        }

    }

    const loadTemplateSchedulerData = (cardData, isScheduler) => {
        setIsLoading(true);
        const convertToLocal = (timeStr) => {
            var [hours, minutes] = timeStr.split(':').map(Number);
            var gmtDate = new Date();
            gmtDate.setUTCHours(hours);
            gmtDate.setUTCMinutes(minutes);
            return gmtDate.getHours().toString().padStart(2, '0') + ":" + gmtDate.getMinutes().toString().padStart(2, '0');
        }
        const setData = (cardData, isScheduler) => {
            if (isScheduler) {
                setScheduleData((prevState) => {
                    return {
                        ...prevState,
                        schedule_id: cardData.job_code,
                        schedule_title: cardData.payload.schedule_title,
                        schedule: {
                            type: cardData.payload.schedule.type,
                            sub_type: cardData.payload.schedule.sub_type ? cardData.payload.schedule.sub_type : "day-of-week",
                            day_selection: cardData.payload.schedule.day_selection ? cardData.payload.schedule.day_selection : ["mon"],
                            trigger_time: cardData.payload.schedule.trigger_time ? (cardData.payload.schedule.trigger_time * 1000) : getDateIn15MinGap(),
                            time_of_day: cardData.payload.schedule.time_of_day ? convertToLocal(cardData.payload.schedule.time_of_day) : "18:00",
                        },
                        app_ids: cardData.payload.app_ids,
                        event_source: cardData.payload.event_source,
                        guide_type: cardData.payload.guide_type,
                        guide_type_breakdown: cardData.payload.guide_type_breakdown,
                        guide_ids: cardData.payload.guide_ids,
                        tooltip_ids: cardData.payload.tooltip_ids,
                        start_timestamp: cardData.payload.start_timestamp ? parseDateString(cardData.payload.start_timestamp) :
                            new Date(new Date().getTime() - (7 * 24 * 60 * 60 * 1000)).getTime(),
                        end_timestamp: cardData.payload.end_timestamp ? parseDateString(cardData.payload.end_timestamp) :
                            new Date().getTime(),
                        export_timeframe_in_days: cardData.payload.export_timeframe_in_days ? cardData.payload.export_timeframe_in_days : 7,
                        schedule_status: cardData.schedule_status,
                        email_cc: cardData.payload.email_cc,
                        selected_id: cardData.job_code,
                        emailLoadFlag: true
                    }
                })

               console.log(cardData.payload.start_timestamp)

               
               setScheduleDataStartDate(
                cardData.payload.start_timestamp
                  ? parseDateString(cardData.payload.start_timestamp) 
                  : new Date(new Date().getTime() - (7 * 24 * 60 * 60 * 1000)) // Creates a Date object 7 days ago
              );
              
              setScheduleDataEndDate(
                cardData.payload.end_timestamp 
                  ? parseDateString(cardData.payload.end_timestamp)
                  : new Date() // Creates a Date object with the current time
              );
                
                

            } else {
                setTemplateData((prevState) => {
                    return {
                        ...prevState,
                        template_id: cardData.job_code,
                        template_title: cardData.payload.template_title,
                        app_ids: cardData.payload.app_ids,
                        event_source: cardData.payload.event_source,
                        guide_type: cardData.payload.guide_type,
                        guide_type_breakdown: cardData.payload.guide_type_breakdown,
                        guide_ids: cardData.payload.guide_ids,
                        tooltip_ids: cardData.payload.tooltip_ids,
                    }
                })
            }
        }

        if (cardData.payload.app_ids.length == 1) {
            let guidePromise;
            let tooltipPromise;
            if (cardData.payload.guide_type.includes('guide')) {
                setGuideData((prevState: any) => {
                    return {
                        ...prevState,
                        isLoading: true,
                        isError: false,
                        lastAppID: cardData.payload.app_ids[0],
                    }
                });
                guidePromise = getGuideTooltipList(cardData.payload.app_ids[0], true);
            }
            if (cardData.payload.guide_type.includes('tooltip')) {
                setTooltipData((prevState: any) => {
                    return {
                        ...prevState,
                        isLoading: true,
                        isError: false,
                        lastAppID: cardData.payload.app_ids[0],
                    }
                });
                tooltipPromise = getGuideTooltipList(cardData.payload.app_ids[0], false);
            }
            Promise.all([guidePromise, tooltipPromise]).then((values) => {
                let guideObj = values[0];
                let tooltipObj = values[1];
                if (guideObj && guideObj.result === "success") {
                    let guideList = guideObj.data.map(guide => ({ key: guide.tour_id, value: guide.tour_title }))
                    setGuideData((prevState: any) => {
                        return {
                            ...prevState,
                            isLoading: false,
                            isError: false,
                            data: guideList,
                        }
                    })
                }

                if (tooltipObj && tooltipObj.result === "success") {

                    let tooltipList = [];
                    tooltipObj.data.map((guide) => {
                        guide['steps'].forEach((item) => {
                            tooltipList.push({ key: item['combined_id'], value: item['combined_title']})
                          });
                    })

                    setTooltipData((prevState: any) => {
                        return {
                            ...prevState,
                            isLoading: false,
                            isError: false,
                            data: tooltipList
                        }
                    })
                }
                setData(cardData, isScheduler)
                setIsLoading(false);
            })
        } else {
            setData(cardData, isScheduler);
            setTimeout(() => setIsLoading(false), 500);
        }
    }

    const loadNewJobTemplate = () => {
        setNewJobData((prevState) => {
            return {
                ...prevState,
                app_ids: templateData.app_ids,
                event_source: templateData.event_source,
                guide_ids: templateData.guide_ids,
                guide_type: templateData.guide_type,
                guide_type_breakdown: templateData.guide_type_breakdown,
                tooltip_ids: templateData.tooltip_ids
            }
        })

        changeTab("exJobList")
    }

    const loadNewTemplate = () => {
        setTemplateData((prevState) => {
            return {
                ...prevState,
                "template_id": "",
                "template_title": "",
                "app_ids": [],
                "guide_ids": [],
                "tooltip_ids": [],
                "guide_type": [],
                "guide_type_breakdown": [],
                "event_source": []
            }
        })
    }

    const loadNewSchedule = () => {
        setScheduleData((prevState: any) => {
            return {
                ...prevState,
                emailLoadFlag: false
            }
        })


        setTimeout(() => {
            setScheduleData((prevState: any) => {
                return {
                    ...prevState,
                    "schedule_id": "",
                    "schedule_title": "",
                    "schedule": {
                        "type": "one-time",
                        "trigger_time": getDateIn15MinGap(),
                        "sub_type": "day-of-week",
                        "day_selection": ["mon"],
                        "time_of_day": "18:00",
                    },
                    "app_ids": [],
                    "guide_ids": [],
                    "tooltip_ids": [],
                    "guide_type": ['guide', 'tooltip'],
                    "guide_type_breakdown": [],
                    "event_source": ['player'],
                    "export_timeframe_in_days": "7",
                    "start_timestamp": new Date(new Date().getTime() - (7 * 24 * 60 * 60 * 1000)).getTime(),
                    "end_timestamp": new Date().getTime(),
                    "schedule_status": "inactive",
                    "email_cc": [],
                    "selected_id": '',
                    emailLoadFlag: true
                }
            })
        }, 1000)


    }

    const getEmailIds = (data: any) => {
        let email_ids = []
        for (let item of data) {
            email_ids.push(item.value)
        }
        setEmailIds((prevState: any) => {
            return {
                ...prevState,
                ids: email_ids,
                valid: true
            }
        })
    }

    const getValidity = (flag: boolean) => {
        setExportBtnDisabled((prevState: any) => {
            return {
                ...prevState,
                valid: flag
            }
        })
    }
    /** Utility methods for list ended */

    /** API calls */
    const getExportListCall = (apiErrorFlag?: string) => {
        const params = {
            'time_zone': timeZone,
        };
        exportActions.getExportListData(
            params,
            "EXPORT_LIST_DATA",
            ActionTypes.SET_EXPORT_LIST_DATA,
            apiErrorFlag ?? '',
            exportList.firstLoad,
        ).then((res: any) => dispatch(res));
    }

    const getExportTemplateCall = (apiErrorFlag?: string) => {
        const params = {
            'time_zone': timeZone,
        };
        exportActions.getExportListData(
            params,
            "EXPORT_TEMPLATE_DATA",
            ActionTypes.SET_EXPORT_TEMPLATE_DATA,
            apiErrorFlag ?? '',
            exportList.firstLoad,
        ).then((res: any) => dispatch(res));
    }

    const getExportSchedulerListCall = (apiErrorFlag?: string) => {
        const params = {
            'time_zone': timeZone,
        };
        exportActions.getExportListData(
            params,
            "EXPORT_SCHEDULER_DATA",
            ActionTypes.SET_EXPORT_SCHEDULER_DATA,
            apiErrorFlag ?? '',
            schedulerList.firstLoad,
        ).then((res: any) => dispatch(res));
    }
    /** API calls */


    /** JSX method started */
    const getExportList = () => {
        if (exportList.listDataFetched === true) {
            if (exportList.apiError === 0) {
                if (exportList.data.length !== 0) {
                    return <div className="exportListFooterDiv">
                        {
                            exportList.data.map((cardData: any, key: number) => {
                                return (
                                    <>
                                        <div className="exportListCardDiv" key={key}>
                                            <div className='exportListCardcontentDiv'>
                                                <div className="requestDateDiv font12">
                                                    {insightsLbls.requestSentOn}:
                                                    <b className="font14 textCapitalize">{cardData.request_time}</b>
                                                </div>
                                                <div className="completedDateDiv font12">
                                                    {insightsLbls.requestCompletedOn}:
                                                    <b className="font14 textCapitalize">{cardData.request_completed_time}</b>
                                                </div>
                                                <div className="jobTypeDiv font12">
                                                    {insightsLbls.requestCompletedvia}:
                                                    <b className="font14 textCapitalize">{getType(cardData.job_type)}</b>
                                                </div>

                                                <div className="jobStatusDiv font12">
                                                    {cardData.job_status === "0" ? (<span className="exportPending">{insightsLbls.pending}</span>) : (cardData.job_status === "1" ? (<span className="exportProcess">{insightsLbls.inProcess}</span>) : (cardData.job_status === "2" ? (<span className="exportComplete">{insightsLbls.complete}</span>) : (cardData.job_status === "3" ? (<span className="exportNoData">{insightsLbls.noRecordFound}</span>) : (<span className="exportFail">{insightsLbls.fail}</span>))))}
                                                </div>
                                            </div>
                                            {arrowIndex.index_up === key && <div className="requestSourceDiv font12">
                                                {insightsLbls.requestSourcedAt}:{modifiedData(cardData.payload.event_source, 'eventsource')}
                                            </div>}
                                            {arrowIndex.index_up === key && <div className="requestSourceDiv font12">
                                                {insightsLbls.requestGuideType}:{modifiedData(cardData.payload.guide_type, 'datasource')}
                                            </div>}
                                            {arrowIndex.index_up === key && <div className="requestSourceDiv font12 displayInline">
                                                {insightsLbls.appsSelectedForRequest}:
                                                {appRequestedData(cardData.payload.app_ids)}
                                            </div>}
                                            <div className="downArrow font12">
                                                <OverlayTrigger placement={"bottom"} overlay={tooltipText(arrowIndex.index_up === key)}>
                                                    <i id={`${key}`} className={'fa fa-angle' + (arrowIndex.index_up !== key ? '-down ' : '-up ') + 'pointer ' + 'arrowWeight'} aria-hidden="true" onClick={toggleArrow}></i>
                                                </OverlayTrigger>
                                            </div>
                                        </div>
                                    </>
                                );
                            }
                            )
                        }
                    </div>
                } else {
                    return <div className="exportListFooterDiv">
                        <div className="loaderHeight width100 displayFlex height100-percent top-30 positionRelative minHeight450">
                            <div className="displayFlex alignCenter margin-0-auto">
                                {insightsLbls.dataNotAvailable}
                            </div>
                        </div>
                    </div>
                }
            } else {
                return <div className="exportListFooterDiv">
                    <div className="loaderHeight width100 displayFlex height100-percent top-30 positionRelative minHeight450">
                        <div className="displayFlex alignCenter margin-0-auto">
                            {insightsLbls.serviceNotAvailable}
                        </div>
                    </div>
                </div>
            }
        } else {
            return <div className="exportListFooterDiv">
                <div className="loaderHeight width100 displayFlex height100-percent top-30 positionRelative minHeight450">
                    <div className="displayFlex alignCenter margin-0-auto">
                        <Loader></Loader>
                    </div>
                </div>
            </div>
        }
    }

    const getTemplateList = () => {
        if (templateList.listDataFetched === true) {
            if (templateList.apiError === 0) {
                if (templateList.data.length !== 0) {
                    return <div className="exportListFooterDiv">
                        {
                            templateList.data.map((cardData: any, key: number) => {
                                return (
                                    <>
                                        <div className="exportListCardDiv" key={key}>
                                            <div className='exportListCardcontentDiv templateBG' onClick={() => loadTemplateSchedulerData(cardData, false)}>
                                                <div className="requestDateDiv font12">
                                                    {insightsLbls.templateTitle}:
                                                    <b className="font14 textCapitalize">{cardData.payload.template_title}</b>
                                                </div>
                                                <div className="completedDateDiv font12">
                                                    {insightsLbls.requestSourcedAt}:{modifiedData(cardData.payload.event_source, 'eventsource')}
                                                </div>
                                                <div className="jobTypeDiv font12" style={{ position: "relative" }}>
                                                    <div className='iconHolder' title={insightsLbls.delete} onClick={(ev) => referToDeleteModal(ev, cardData.job_code, 'template')}>
                                                        <i className="fa fa-solid fa-trash"></i>
                                                    </div>
                                                </div>
                                            </div>
                                            {arrowIndex.index_up === key && <div className="requestSourceDiv font12">
                                                {insightsLbls.requestGuideType}:{modifiedData(cardData.payload.guide_type, 'datasource')}
                                            </div>}
                                            {arrowIndex.index_up === key && <div className="requestSourceDiv font12 displayInline">
                                                {insightsLbls.appsSelectedForRequest}:
                                                {appRequestedData(cardData.payload.app_ids)}
                                            </div>}
                                            <div className="downArrow font12">
                                                <OverlayTrigger placement={"bottom"} overlay={tooltipText(arrowIndex.index_up === key)}>
                                                    <i id={`${key}`} className={'fa fa-angle' + (arrowIndex.index_up !== key ? '-down ' : '-up ') + 'pointer ' + 'arrowWeight'} aria-hidden="true" onClick={toggleArrow}></i>
                                                </OverlayTrigger>
                                            </div>
                                        </div>
                                    </>
                                );
                            }
                            )
                        }
                    </div>
                } else {
                    return <div className="exportListFooterDiv">
                        <div className="loaderHeight width100 displayFlex height100-percent top-30 positionRelative minHeight450">
                            <div className="displayFlex alignCenter margin-0-auto">
                                {insightsLbls.dataNotAvailable}
                            </div>
                        </div>
                    </div>
                }
            } else {
                return <div className="exportListFooterDiv">
                    <div className="loaderHeight width100 displayFlex height100-percent top-30 positionRelative minHeight450">
                        <div className="displayFlex alignCenter margin-0-auto">
                            {insightsLbls.serviceNotAvailable}
                        </div>
                    </div>
                </div>
            }
        } else {
            return <div className="exportListFooterDiv">
                <div className="loaderHeight width100 displayFlex height100-percent top-30 positionRelative minHeight450">
                    <div className="displayFlex alignCenter margin-0-auto">
                        <Loader></Loader>
                    </div>
                </div>
            </div>
        }
    }

    const getSchedulerList = () => {
        if (schedulerList.listDataFetched === true) {
            if (schedulerList.apiError === 0) {
                if (schedulerList.data.length !== 0) {
                    return <div className="exportListFooterDiv">
                        {
                            schedulerList.data.map((cardData: any, key: number) => {
                                return (
                                    <>
                                        <div className="exportListCardDiv" key={key}>
                                            <div className='exportListCardcontentDiv templateBG'
                                                style={{ backgroundColor: cardData.job_code === scheduleData.selected_id ? '#f1fcfa' : '' }}
                                                onClick={() => loadTemplateSchedulerData(cardData, true)}>
                                                <div className="requestDateDiv font12">
                                                    {insightsLbls.scheduleTitle}:
                                                    <b className="font14 textCapitalize">{cardData.payload.schedule_title}</b>
                                                </div>
                                                <div className="completedDateDiv font12">
                                                    {insightsLbls.requestSourcedAt}:{modifiedData(cardData.payload.event_source, 'eventsource')}
                                                </div>
                                                <div className="jobTypeDiv font12" style={{ position: "relative" }}>
                                                    <div className='iconHolder' title={insightsLbls.delete} onClick={(ev) => referToDeleteModal(ev, cardData.job_code, "scheduler")}>
                                                        <i className="fa fa-solid fa-trash"></i>
                                                    </div>
                                                </div>
                                                <div className="jobTypeDiv font12" style={{ position: "relative" }}>
                                                    <div className='iconHolder'
                                                        title={cardData.schedule_status == "active"
                                                            ? insightsLbls.deactivate : insightsLbls.activate}
                                                        onClick={() => referToPlayPauseModal(cardData.job_code, cardData.schedule_status)}>
                                                        {cardData.schedule_status == "active" && <i className="fa fa-solid fa-pause"></i>}
                                                        {cardData.schedule_status == "inactive" && <i className="fa fa-solid fa-play"></i>}
                                                    </div>
                                                </div>
                                            </div>
                                            {arrowIndex.index_up === key && <div className="requestSourceDiv font12" style={{ backgroundColor: cardData.job_code === scheduleData.selected_id ? '#cff2ec' : '' }}>
                                                {insightsLbls.requestGuideType}:{modifiedData(cardData.payload.guide_type, 'datasource')}
                                            </div>}
                                            {arrowIndex.index_up === key && <div className="requestSourceDiv font12 displayInline" style={{ backgroundColor: cardData.job_code === scheduleData.selected_id ? '#cff2ec' : '' }}>
                                                {insightsLbls.appsSelectedForRequest}:
                                                {appRequestedData(cardData.payload.app_ids)}
                                            </div>}
                                            <div className="downArrow font12" style={{ backgroundColor: cardData.job_code === scheduleData.selected_id ? '#cff2ec' : '' }} >
                                                <OverlayTrigger placement={"bottom"} overlay={tooltipText(arrowIndex.index_up === key)}>
                                                    <i id={`${key}`} className={'fa fa-angle' + (arrowIndex.index_up !== key ? '-down ' : '-up ') + 'pointer ' + 'arrowWeight'} aria-hidden="true" onClick={toggleArrow}></i>
                                                </OverlayTrigger>
                                            </div>
                                        </div>
                                    </>
                                );
                            }
                            )
                        }
                    </div>
                } else {
                    return <div className="exportListFooterDiv">
                        <div className="loaderHeight width100 displayFlex height100-percent top-30 positionRelative minHeight450">
                            <div className="displayFlex alignCenter margin-0-auto">
                                {insightsLbls.dataNotAvailable}
                            </div>
                        </div>
                    </div>
                }
            } else {
                return <div className="exportListFooterDiv">
                    <div className="loaderHeight width100 displayFlex height100-percent top-30 positionRelative minHeight450">
                        <div className="displayFlex alignCenter margin-0-auto">
                            {insightsLbls.serviceNotAvailable}
                        </div>
                    </div>
                </div>
            }
        } else {
            return <div className="exportListFooterDiv">
                <div className="loaderHeight width100 displayFlex height100-percent top-30 positionRelative minHeight450">
                    <div className="displayFlex alignCenter margin-0-auto">
                        <Loader></Loader>
                    </div>
                </div>
            </div>
        }
    }

    const getNewJobTemplate = () => {
        return <div className="exportRequestDiv">
            <div className="exportRequestHeaderDiv">
                <div className="exportRequestTitle">
                    {insightsLbls.exportViaEmail}
                </div>
                <div className="exportRequestButtonDiv">
                    <button
                        className={"source-but btn displayFlex tooltipFilter marginRight-0"}
                        data-toggle="modal"
                        onClick={() => exportExcelRequest()}
                        disabled={appsFetched.isFetched ? (exportBtnDisabled.exportBtnDisabled) : false}
                    >
                        <img src={require("../../assets/images/export-excel.png")}
                            width="22" height="auto" alt="" className="excel-icon" />
                        {insightsLbls.exportJobs}
                    </button>
                </div>
            </div>
            {exportBtnDisabled.exportBtnDisabled && <div className="requestLimitMsg">
                <p className="errorMsg displayBlock positionAbsolute top10 left10">
                    *{insightsLbls.placeRequestLimitMsg}
                </p>
            </div>}
            <div className="positionAbsolute top90 width100 row marginLR-0 paddingLeft-16 exportCards-div">
                <div className="col-sm-12 col-md-12 col-lg-12 col-12 marginBottom-10 paddingLeft-16 paddingRight-20">
                    <InputLabel dispatchEmailIds={getEmailIds} dispatchValidity={getValidity} email_cc={[]}></InputLabel>
                </div>

                <div className="col-sm-6 col-md-6 col-lg-6 col-6">{getEventSourceCard(newJobData.event_source)}</div>
                <div className="col-sm-6 col-md-6 col-lg-6 col-6">{getDatePickerCard()}</div>
                <div className="col-sm-12 col-md-12 col-lg-12 col-12">
                    {getAppPlayTypeCard(newJobData.app_ids, newJobData.guide_type, newJobData.guide_type_breakdown, newJobData.guide_ids, newJobData.tooltip_ids)}
                </div>
            </div>
        </div>
    }

    const getTemplateCreationTemplate = () => {
        return <div className="exportRequestDiv">
            <div className="exportRequestHeaderDiv">
                <div className="exportRequestTitle">
                    {insightsLbls.exportViaEmail}
                </div>
                <div className="exportRequestButtonDiv">
                    <button
                        className={"btn font14 useTemplateBtn"}
                        data-toggle="modal"
                        onClick={() => loadNewJobTemplate()}
                        disabled={templateData.template_id == "" ? true : false}>
                        {insightsLbls.useTemplate}
                    </button>
                    <button
                        className={"btn saveTemplateBtn 12 font14"}
                        data-toggle="modal"
                        onClick={() => saveTemplate(templateData.template_id != "")}>

                        {templateData.template_id != "" ? insightsLbls.updateTemplate : insightsLbls.saveTemplate}
                    </button>
                </div>
            </div>
            {isLoading && <Loader></Loader>}
            {!isLoading && <div className="positionAbsolute top90 width100 row marginLR-0 paddingLeft-16 exportCards-div">
                <div className="col-sm-12 col-md-12 col-lg-12 col-12">
                    <input type="text" className="form-control round-input templateTitleInput"
                        name="templateTitle" value={templateData.template_title}
                        onChange={(event) => updateTemplateTitle(event.target.value)} placeholder={insightsLbls.templateTitle}></input>
                </div>
                <div className="col-sm-12 col-md-12 col-lg-12 col-12">
                    {templateData.template_id == "" && getEventSourceCard(['player'])}
                    {templateData.template_id != "" && getEventSourceCard(templateData.event_source)}
                </div>
                <div className="col-sm-12 col-md-12 col-lg-12 col-12">
                    {templateData.template_id == "" && getAppPlayTypeCard([], ['guide', 'tooltip'], [], [], [])}
                    {templateData.template_id != "" && getAppPlayTypeCard(templateData.app_ids, templateData.guide_type, templateData.guide_type_breakdown,
                        templateData.guide_ids, templateData.tooltip_ids)}
                </div>
            </div>}
        </div>
    }

    const getSchedulerTemplate = () => {
        return <div className="exportRequestDiv">
            <div className="exportRequestHeaderDiv">
                <div className="exportRequestTitle">
                    {insightsLbls.exportViaEmail}
                </div>
                <div className="exportRequestButtonDiv">
                    <button
                        className={"btn saveTemplateBtn font14"}
                        data-toggle="modal"
                        onClick={() => saveSchedule(scheduleData.schedule_id != "")}
                        disabled={scheduleData.schedule_id != "" ? scheduleData.schedule_status == "active" : false}>
                        {scheduleData.schedule_id != "" ? insightsLbls.updateSchedule : insightsLbls.saveSchedule}
                    </button>
                </div>
            </div>
            {isLoading && <Loader></Loader>}
            {!isLoading && <div className="positionAbsolute top90 width100 row marginLR-0 paddingLeft-16 exportCards-div">
                {scheduleData.schedule_id != "" && scheduleData.schedule_status == "active" && <p className='text-danger font12'>
                    {insightsLbls.activeScheduleNonEditableInfoMsg}</p>}

                <div className="col-sm-12 col-md-12 col-lg-12 col-12 paddingRight-25">
                    <input type="text" className="form-control round-input templateTitleInput"
                        name="scheduleTitle" value={scheduleData.schedule_title}
                        onChange={(event) => updateScheduleTitle(event.target.value)} placeholder={insightsLbls.scheduleTitle}></input>
                </div>
                {scheduleData.emailLoadFlag && (
                    <div className="col-sm-12 col-md-12 col-lg-12 col-12 marginBottom-10 paddingLeft-16 paddingRight-20">
                        <InputLabel dispatchEmailIds={getEmailIds} dispatchValidity={getValidity} email_cc={scheduleData.email_cc}></InputLabel>
                    </div>
                )}

                <div className="col-sm-6 col-md-6 col-lg-6 col-6 marginBottm-20">
                    {getFilterCard()}
                </div>
                <div className="col-sm-6 col-md-6 col-lg-6 col-6 marginBottm-20">
                    {scheduleData.schedule_id == "" && scheduleData.schedule.type == "one-time" && getStartEndDatePickerCard()}
                    {scheduleData.schedule_id != "" && scheduleData.schedule.type == "one-time" && getStartEndDatePickerCard()}
                    {scheduleData.schedule_id == "" && scheduleData.schedule.type == "recurring" && getRecurringDatePickerCard(4)}
                    {scheduleData.schedule_id != "" && scheduleData.schedule.type == "recurring" && getRecurringDatePickerCard(5)}
                </div>
                <br></br><br></br>
                <div className="col-sm-12 col-md-12 col-lg-12 col-12">
                    {scheduleData.schedule_id == "" && getEventSourceCard(['player'])}
                    {scheduleData.schedule_id != "" && getEventSourceCard(scheduleData.event_source)}
                </div>
                <div className="col-sm-12 col-md-12 col-lg-12 col-12">
                    {scheduleData.schedule_id == "" && getAppPlayTypeCard([], ['guide', 'tooltip'], [], [], [])}
                    {scheduleData.schedule_id != "" && getAppPlayTypeCard(scheduleData.app_ids, scheduleData.guide_type, scheduleData.guide_type_breakdown,
                        scheduleData.guide_ids, scheduleData.tooltip_ids)}
                </div>
            </div>}
        </div>
    }
    /** JSX method ended */

    /** fetching list for export/template/scheduler ended*/

    /** CRUD Operations */

    const exportExcelRequest = () => {
        try {
            setArrowIndex((prevState: any) => {
                return {
                    ...prevState,
                    index_up: null
                };
            });
            if (appsFetched.isFetched) {
                const data = {
                    'start_timestamp': fetched_details?.exportDates?.export_fdate + "_00:00:00",
                    'end_timestamp': fetched_details?.exportDates?.export_tdate + "_23:59:59",
                    'app_ids': selectedAppData,
                    "event_source": selectedEventSourceData,
                    "guide_type": selectedDataSourceData,
                    "guide_ids": (selectedAppData.length == 1 && selectedDataSourceData.includes('guide')) && guideData.data.length != selectedGuideData.length
                        ? selectedGuideData : [],
                    "tooltip_ids": (selectedAppData.length == 1 && selectedDataSourceData.includes('tooltip') && tooltipData.data.length != selectedTooltipData.length)
                        ? selectedTooltipData : [],
                    "guide_type_breakdown": selectedDataSourceData.includes('guide') ? selectedPlayTypeData : [],
                    "email_cc": emailIds.ids
                };

                const param = {
                    'time_zone': timeZone,
                };

                let path = ApiRelativePaths.EXPORT_REQUEST;
                let paramObj = {};
                let a = CommonUtils.URLGenerator(param, path, paramObj);
                postCall(data, "EXPORT_REQUEST", param).then((data: any) => {
                    if (data.result === "success") {

                        toast.success(insightsLbls.requestSent, {
                            position: "top-right",
                            autoClose: 3000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });

                        getExportListCall();
                    }
                });
            }
        } catch (error) {
            showBoundary(error)
        }
    }

    const saveTemplate = (isEdit) => {
        if (templateData.template_title != "") {
            const utilMethod = (res) => {
                if (res.result === "success") {
                    toast.success(isEdit ? insightsLbls.updatedSuccessfully : insightsLbls.requestSent, {
                        position: "top-right",
                        autoClose: 3000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    loadNewTemplate();
                    getExportTemplateCall();
                }
            }
            if (appsFetched.isFetched) {
                const { template_id, template_title, app_ids, event_source, guide_type, guide_ids, tooltip_ids, guide_type_breakdown } = templateData;
                const param = { 'time_zone': timeZone };
                if (isEdit) {
                    let data = {
                        "job_code": templateData.template_id,
                        template_id: template_id,
                        template_title: template_title,
                        app_ids: app_ids,
                        event_source: event_source,
                        guide_type: guide_type,
                        guide_ids: (app_ids.length == 1 && guide_type.includes('guide')) && guideData.data.length != guide_ids.length
                            ? guide_ids : [],
                        tooltip_ids: (app_ids.length == 1 && guide_type.includes('tooltip')) && tooltipData.data.length != tooltip_ids.length
                            ? tooltip_ids : [],
                        guide_type_breakdown: guide_type_breakdown
                    };
                    putCall(data, "SAVE_TEMPLATE_DATA", '').then((res: any) => {
                        utilMethod(res);
                    });
                } else {
                    let data = {
                        template_title: template_title,
                        app_ids: app_ids,
                        event_source: event_source,
                        guide_type: guide_type,
                        guide_ids: (app_ids.length == 1 && guide_type.includes('guide')) && guideData.data.length != guide_ids.length
                            ? guide_ids : [],
                        tooltip_ids: (app_ids.length == 1 && guide_type.includes('tooltip')) && tooltipData.data.length != tooltip_ids.length
                            ? tooltip_ids : [],
                        guide_type_breakdown: guide_type_breakdown
                    };
                    postCall(data, "SAVE_TEMPLATE_DATA", param).then((res: any) => {
                        utilMethod(res);
                    });
                }
            }
        } else {
            toast.error(insightsLbls.templateTitleErrorMsg, {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    }

    const saveSchedule = (isEdit) => {
        const convertToGMT = (timeStr) => {
            // Parse the time string to get hours and minutes
            var [hours, minutes] = timeStr.split(':').map(Number);

            // Create a date object with the given time and timezone
            var date = new Date();
            date.setHours(hours);
            date.setMinutes(minutes);

            // Format the GMT time as HH:MM
            var gmtHours = date.getUTCHours().toString().padStart(2, '0');
            var gmtMinutes = date.getUTCMinutes().toString().padStart(2, '0');

            return gmtHours + ':' + gmtMinutes;
        }

        const getDateFromEpoch = (dt) => {
            let tempDate = new Date(dt);
            return tempDate.getFullYear() + "-" + (tempDate.getMonth() + 1).toString().padStart(2, '0') + "-" +
                (tempDate.getDate()).toString().padStart(2, '0') + "_" + "00:00:00"
        }

        if (validateSave()) {
            const utilMethod = (res => {
                if (res.result === "success") {
                    toast.success(isEdit ? insightsLbls.updatedSuccessfully : insightsLbls.requestSent, {
                        position: "top-right",
                        autoClose: 3000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    loadNewSchedule();
                    getExportSchedulerListCall();
                }
            });
            if (appsFetched.isFetched) {
                const param = { 'time_zone': timeZone };
                let data;
                const { schedule_title, app_ids, event_source, guide_type, guide_ids, tooltip_ids, guide_type_breakdown } = scheduleData;

                if (scheduleData.schedule.type == "one-time") {
                    data = {
                        schedule: {
                            "type": scheduleData.schedule.type,
                            "trigger_time": parseInt((scheduleData.schedule.trigger_time / 1000).toString())
                        },
                        start_timestamp: getDateFromEpoch(scheduleData.start_timestamp),
                        end_timestamp: getDateFromEpoch(scheduleData.end_timestamp),
                        schedule_title: schedule_title,
                        app_ids: app_ids,
                        event_source: event_source,
                        guide_type: guide_type,
                        guide_ids: (app_ids.length == 1 && guide_type.includes('guide')) && guideData.data.length != guide_ids.length
                            ? guide_ids : [],
                        tooltip_ids: (app_ids.length == 1 && guide_type.includes('tooltip')) && tooltipData.data.length != tooltip_ids.length
                            ? tooltip_ids : [],
                        guide_type_breakdown: guide_type_breakdown,
                        email_cc: emailIds.ids
                    }

               console.log(new Date(scheduleData.end_timestamp))


                    setScheduleDataEndDate(new Date(scheduleData.end_timestamp));
                    setScheduleDataStartDate(new Date(scheduleData.start_timestamp));
                
                } else {
                    data = {
                        schedule: {
                            type: scheduleData.schedule.type,
                            sub_type: scheduleData.schedule.sub_type,
                            day_selection: scheduleData.schedule.day_selection,
                            time_of_day: convertToGMT(scheduleData.schedule.time_of_day)
                        },
                        schedule_title: schedule_title,
                        app_ids: app_ids,
                        event_source: event_source,
                        guide_type: guide_type,
                        guide_ids: (app_ids.length == 1 && guide_type.includes('guide')) && guideData.data.length != guide_ids.length
                            ? guide_ids : [],
                        tooltip_ids: (app_ids.length == 1 && guide_type.includes('tooltip')) && tooltipData.data.length != tooltip_ids.length
                            ? tooltip_ids : [],
                        guide_type_breakdown: guide_type_breakdown,
                        export_timeframe_in_days: scheduleData.export_timeframe_in_days,
                        email_cc: emailIds.ids
                    }
                }

                if (isEdit) {
                    data = {
                        ...data,
                        job_code: scheduleData.schedule_id
                    }
                    putCall(data, "SAVE_SCHEDULE_DATA", '').then((res: any) => {
                        utilMethod(res);
                    })
                } else {
                    postCall(data, "SAVE_SCHEDULE_DATA", param).then((res: any) => {
                        utilMethod(res);
                    });
                }
            }
        }
    }

    const refreshList = () => {
        try {
            if (pageTab.key == "exJobList") {
                setExportList((prevState: any) => {
                    return {
                        ...prevState,
                        data: [],
                        apiError: 0,
                        firstLoad: true,
                        listDataFetched: false
                    };
                })
                getExportListCall();
            } else if (pageTab.key == "exTemList") {
                setTemplateList((prevState: any) => {
                    return {
                        ...prevState,
                        data: [],
                        apiError: 0,
                        firstLoad: true,
                        listDataFetched: false
                    };
                })
                getExportTemplateCall();
            } else if (pageTab.key == "exSchList") {
                setSchedulerList((prevState: any) => {
                    return {
                        ...prevState,
                        data: [],
                        apiError: 0,
                        firstLoad: true,
                        listDataFetched: false
                    };
                })
                getExportSchedulerListCall();
            }
        } catch (error) {
            showBoundary(error)
        }
    }

    /** CRUD Operations */

    /** Modal method started */

    const [deleteModal, setDeleteModal] = useState({
        open: false,
        id: "",
        optn: ''
    })

    const closeDeleteModal = () => {
        setDeleteModal((prevState: any) => {
            return {
                ...prevState,
                open: false
            }
        })
    }

    const referToDeleteModal = (ev, job_code, optn) => {
        ev.stopPropagation();
        setDeleteModal((prevState: any) => {
            return {
                ...prevState,
                open: true,
                id: job_code,
                optn: optn
            }
        })
    }

    const deleteFromModal = () => {
        let type = "";
        let params = { job_code: deleteModal.id };
        if (deleteModal.optn == 'template') {
            type = "SAVE_TEMPLATE_DATA";
        } else if (deleteModal.optn == 'scheduler') {
            type = "EXPORT_SCHEDULE_UPDATE";
        }
        if (type != "") {
            deleteCall(params, type, "").then((data: any) => {
                if (data.result === "success") {
                    closeDeleteModal();
                    toast.error(insightsLbls.successfullyDeleted, {
                        position: "top-right",
                        autoClose: 3000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    if (deleteModal.optn == 'template') {
                        getExportTemplateCall();
                        if (templateData.template_id === deleteModal.id) {
                            loadNewTemplate();
                        }
                    } else if (deleteModal.optn == 'scheduler') {
                        getExportSchedulerListCall();
                        if (scheduleData.schedule_id === deleteModal.id) {
                            loadNewSchedule();
                        }
                    }
                }
            });
        }
    }

    const [playPauseModal, setPlayPauseModal] = useState({
        open: false,
        id: "",
        optn: ''
    })

    const closePlayPauseModal = () => {
        setPlayPauseModal((prevState: any) => {
            return {
                ...prevState,
                open: false
            }
        })
    }

    const referToPlayPauseModal = (job_code, optn) => {
        if (optn == "inactive") {
            let count = 0;
            for (let i = 0; i < schedulerList.data.length; i++) {
                if (schedulerList.data[i].schedule_status == "active") {
                    count++;
                }
            }

            if (count < 3) {
                let originalSchedularData = schedulerList.data.find(sch => sch.job_code === job_code);
                if (originalSchedularData.payload.schedule.type == "one-time") {
                    let currentTime;
                    if (scheduleData.schedule_id != "" && scheduleData.schedule_id == job_code) {
                        //already in milliseconds
                        currentTime = scheduleData.schedule.trigger_time;
                    } else {
                        //convert to milliseconds
                        currentTime = originalSchedularData.payload.schedule.trigger_time * 1000;
                    }
                    if (currentTime < new Date().getTime()) {
                        //check if date is crossed for schedule run
                        toast.error(insightsLbls.scheduleFutureDateErrorMsg, {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                    } else {
                        setPlayPauseModal((prevState: any) => {
                            return {
                                ...prevState,
                                open: true,
                                id: job_code,
                                optn: optn
                            }
                        })
                    }
                } else {
                    //for recurring update
                    setPlayPauseModal((prevState: any) => {
                        return {
                            ...prevState,
                            open: true,
                            id: job_code,
                            optn: optn
                        }
                    })
                }
            } else {
                toast.error(insightsLbls.scheduleMaxActiveErrorMsg, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        } else {
            setPlayPauseModal((prevState: any) => {
                return {
                    ...prevState,
                    open: true,
                    id: job_code,
                    optn: optn
                }
            })
        }
    }

    const validateSave = () => {
        let errMessage = "";
        if (scheduleData.schedule_title == "") {
            errMessage = insightsLbls.scheduleTitleErrorMsg;
        }

        if (scheduleData.schedule.type == "one-time" && scheduleData.schedule.trigger_time < new Date().getTime()) {
            errMessage = insightsLbls.scheduleFutureDateErrorMsg;
        }

        if (scheduleData.schedule.type == "recurring" && scheduleData.schedule.day_selection.length == 0) {
            errMessage = insightsLbls.scheduleDaySelectionErrorMsg;
        }

        if (scheduleData.schedule.day_selection.length > 7) {
            errMessage = insightsLbls.scheduleMaxDaySelectionErrorMsg;
        }

        if (errMessage != "") {
            toast.error(errMessage, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            return false;
        }
        return true;
    }



    const playPauseUpdate = () => {
        let params = { "job_code": playPauseModal.id };
        let payload = { "schedule_status": playPauseModal.optn == 'active' ? "inactive" : "active" };
        patchCall(params, payload, "EXPORT_SCHEDULE_UPDATE", '').then((data: any) => {
            if (data.result === 'success') {
                toast.success(data.data.data.message, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                getExportSchedulerListCall();
            } else if (data.result === 'error') {
                if (data.error) {
                    toast.error(data.error, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }
            }
        });
    }

    /** Modal method ended */

    /** Use effect started */

    useEffect(() => {
        if (fetched_details.languageData.languageData) {
            setInsightsLbls(fetched_details.languageData.languageData);
        }
    }, [fetched_details.languageData.languageData])

    useEffect(() => {
        document.title = insightsLbls.export;
    }, [insightsLbls])

    useEffect(() => {
        window.scrollTo(0, 0);
        let queryParams = {}
        navigate({
            pathname: `/export/export-list/`,
            search: qs.stringify(queryParams)
        })
        CommonUtils.LeftPanelHighlight(8, 0, 0, false, false);


        if (isInitialMount.current) {
            isInitialMount.current = false;
            getAppList();
            getExportListCall();
        }
          
       
    }, [])

    useEffect(() => {
        try {
            if (
                fetched_details.export.exportListData.data !== undefined &&
                fetched_details.export.exportListData.result === "success"
            ) {
                setExportList((prevState: any) => {
                    return {
                        ...prevState,
                        data: fetched_details.export.exportListData.data,
                        listDataFetched: true,
                        apiError: 0,
                    }
                })
                setExportBtnDisabled((prevState: any) => {
                    return {
                        ...prevState,
                        exportBtnDisabled: fetched_details.export.exportListData.data.length !== 0 ? ((fetched_details.export.exportListData.data[0]['job_status'] === '0' || fetched_details.export.exportListData.data[0]['job_status'] === '1') ? (true) : (false)) : (false)

                    }
                })
            }
            else if (fetched_details.export.exportListData.result === 'retry') {
                getExportListCall('retry');
            }
            else if (fetched_details.export.exportListData.result === "error") {
                setExportList((prevState: any) => {
                    return {
                        ...prevState,
                        listDataFetched: true,
                        apiError: 1,
                    }
                })
            }
        } catch (error) {
            showBoundary(error)
        }
    }, [fetched_details.export.exportListData])

    useEffect(() => {
        try {
            if (
                fetched_details.export.exportTemplateData.data !== undefined &&
                fetched_details.export.exportTemplateData.result === "success"
            ) {
                setTemplateList((prevState: any) => {
                    return {
                        ...prevState,
                        data: fetched_details.export.exportTemplateData.data,
                        listDataFetched: true,
                        apiError: 0,
                    }
                })
            }
            else if (fetched_details.export.exportTemplateData.result === 'retry') {
                getExportTemplateCall('retry');
            }
            else if (fetched_details.export.exportTemplateData.result === "error") {
                setTemplateList((prevState: any) => {
                    return {
                        ...prevState,
                        listDataFetched: true,
                        apiError: 1,
                    }
                })
            }
        } catch (error) {
            showBoundary(error)
        }
    }, [fetched_details.export.exportTemplateData])

    useEffect(() => {
        try {
            if (
                fetched_details.export.exportSchedulerData.data !== undefined &&
                fetched_details.export.exportSchedulerData.result === "success"
            ) {
                setSchedulerList((prevState: any) => {
                    return {
                        ...prevState,
                        data: fetched_details.export.exportSchedulerData.data,
                        listDataFetched: true,
                        apiError: 0,
                    }
                })

                if (scheduleData.schedule_id != "") {
                    let scheduleObj = fetched_details.export.exportSchedulerData.data.find(obj => obj.job_code == scheduleData.schedule_id);
                    if (scheduleObj) {
                        loadTemplateSchedulerData(scheduleObj, true);
                    }
                }
            }
            else if (fetched_details.export.exportSchedulerData.result === 'retry') {
                getExportSchedulerListCall('retry');
            }
            else if (fetched_details.export.exportSchedulerData.result === "error") {
                setSchedulerList((prevState: any) => {
                    return {
                        ...prevState,
                        listDataFetched: true,
                        apiError: 1,
                    }
                })
            }
        } catch (error) {
            showBoundary(error)
        }

    }, [fetched_details.export.exportSchedulerData])

    useEffect(() => {
        if (selectedAppData.length == 1) {
            /*Fetch data only if guides or tooltip is selected in data source, already api call is not in pending request
            & either last call has error or last app id was diff. from current app id, to remove unnecessary calling*/
            if (!guideData.isLoading && selectedDataSourceData.includes("guide") && (guideData.isError || selectedAppData[0] != guideData.lastAppID)) {
                /* condition to set selected guide as empty, so if in update mode, user change application multiple times, 
                 then selected guide will be updated accordingly */
                if (scheduleData.guide_ids.length > 0) {
                    setSelectedGuideData([]);
                }
                //set loading to true
                setGuideData((prevState: any) => {
                    return {
                        ...prevState,
                        isLoading: true,
                        isError: false,
                        lastAppID: selectedAppData[0],
                    }
                });
                //fetch guide data
                getGuideTooltipList(selectedAppData[0], true).then(res => {
                    if (res["result"] === "success") {
                        let guideList = res["data"].map(guide => ({ key: guide.tour_id, value: guide.tour_title }))
                        setGuideData((prevState: any) => {
                            return {
                                ...prevState,
                                isLoading: false,
                                isError: false,
                                data: guideList
                            }
                        })
                    } else {
                        setGuideData((prevState: any) => {
                            return {
                                ...prevState,
                                isLoading: false,
                                isError: true,
                                data: []
                            }
                        });
                    }
                });
            }
            if (!tooltipData.isLoading && selectedDataSourceData.includes("tooltip") && (tooltipData.isError || selectedAppData[0] != tooltipData.lastAppID)) {
                /* condition to set selected guide as empty, so if in update mode, user change application multiple times, 
                then selected guide will be updated accordingly */
                if (scheduleData.tooltip_ids.length > 0) {
                    setSelectedTooltipData([]);
                }
                //set loading to true
                setTooltipData((prevState: any) => {
                    return {
                        ...prevState,
                        isLoading: true,
                        isError: false,
                        lastAppID: selectedAppData[0]
                    }
                })
                //fetch tooltip data
                getGuideTooltipList(selectedAppData[0], false).then(res => {
                    if (res["result"] === "success") {

                        let tooltipList = [];
                        res["data"].map((guide) => {
                            guide['steps'].forEach((item) => {
                                tooltipList.push({ key: item['combined_id'], value: item['combined_title']})
                              });
                        })

                        // let tooltipList = res["data"].map(guide => ({ key: guide.tour_id, value: guide.tour_title }))

                        setTooltipData((prevState: any) => {
                            return {
                                ...prevState,
                                isLoading: false,
                                isError: false,
                                data: tooltipList
                            }
                        })
                    } else {
                        setTooltipData((prevState: any) => {
                            return {
                                ...prevState,
                                isLoading: false,
                                isError: true,
                                data: []
                            }
                        });
                    }
                });
            }
        }
    }, [selectedDataSourceData, selectedAppData])

    useEffect(() => {
        if (pageTab.key == "exTemList") {
            setTemplateData((prevState) => {
                return {
                    ...prevState,
                    event_source: selectedEventSourceData,
                    app_ids: selectedAppData,
                    guide_type: selectedDataSourceData,
                    guide_type_breakdown: selectedPlayTypeData,
                    guide_ids: selectedGuideData,
                    tooltip_ids: selectedTooltipData
                }
            })
        } if (pageTab.key == "exSchList") {
            setScheduleData((prevState) => {
                return {
                    ...prevState,
                    event_source: selectedEventSourceData,
                    app_ids: selectedAppData,
                    guide_type: selectedDataSourceData,
                    guide_type_breakdown: selectedPlayTypeData,
                    guide_ids: selectedGuideData,
                    tooltip_ids: selectedTooltipData
                }
            })
        }
    }, [selectedEventSourceData, selectedAppData, selectedDataSourceData, selectedPlayTypeData, selectedGuideData, selectedTooltipData])

    /** Use effect ended */



    const tooltipText = (selected: boolean) => {
        try {
            return (<Tooltip id="tooltipText">
                <br></br>
                <div><span className="fontGreyCard">{!selected ? insightsLbls.moreDetails + '...' : insightsLbls.lessDetails + '...'}</span></div>
                <br></br>
            </Tooltip>)
        } catch (error) {
            showBoundary(error)
        }

    }

    const toggleArrow = (event: any) => {
        try {
            setArrowIndex((prevState: any) => {
                return {
                    ...prevState,
                    index_up: prevState.index_up === +event.target.id ? null : +event.target.id
                };
            });
        } catch (error) {
            showBoundary(error)
        }
    }

    const getType = (type: string) => {
        try {
            if (type === 'email') {
                return insightsLbls.email
            } else {
                return type
            }
        } catch (error) {
            showBoundary(error)
        }
    }

    const getDatePickerCard = () => {

        return <div className="exportRequestDatePickerDiv">
            <div className="exportRequestCardDiv">
                <div className="exportRequestFilterDiv dateDiv">
                    <Datepicker source="exportEmail"></Datepicker>
                </div>
                <div className="exportRequestFilterInformationDiv">
                    <div className="exportRequestFilterListTitleDiv">
                        {insightsLbls.selectedDateRange}:
                    </div>
                    {
                        <div className="exportRequestDateListDiv">
                            {
                                calendarOptions.map((data: any, key: number) => {
                                    return (
                                       
                                        fetched_details.exportDates.dateSelection == data.dateSelection ?
                                            (
                                                <>
                                                    <p className="font14 chipParagraph">
                                                        <span className="infoChip dateChips">{`${data.text} (${fetched_details.exportDates.export_fdate} to ${fetched_details.exportDates.export_tdate})`}</span>
                                                    </p>
                                                </>
                                            )
                                            :
                                            (null)
                                    );
                                })
                            }
                        </div>
                    }
                </div>
            </div>
        </div>
    }

    const getFilterCard = () => {
        return <div className="exportRequestDatePickerDiv">
            <div className="exportRequestCardDiv">
                <div className="exportRequestFilterDiv dateDiv positionRelative">
                    {getFrequencyDropdown()}
                    {scheduleData.schedule.type == "recurring" && getFrequencySubTypeDropdown()}
                </div>
                <div className="exportRequestFilterInformationDiv">
                    <div className="exportRequestFilterListTitleDiv displayFlexColumn">
                        {scheduleData.schedule.type == "recurring" && <div className='daySelectionDiv'>
                            <div>{scheduleData.schedule.sub_type == "day-of-week" ? insightsLbls.dayOfWeek : insightsLbls.dateOfMonth} </div>
                            <div className='daySelectDropdown'>{getDaySelectionDropdown()}</div>
                        </div>
                        }
                        <div className={`timeSelectionDiv ${scheduleData.schedule.type == "one-time" ? ' popperDivOneTime' : ''}`} >
                            <div>{insightsLbls.triggerTime}: </div>
                            <div>{scheduleData.schedule.type == "one-time" ? getDateTimeDropdown() : getTimeDropdown()}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    }

    const getAppPlayTypeCard = (appSelected, dataSourceSelected, playTypeSelected, selectedGuide, selectedTooltip) => {
        return <div className="exportRequestAppPickerDiv" >
            <div className="exportRequestCardDiv appPlayContainer">
                <div className="exportRequestFilterDiv appPlayDiv displayFlex col-12 col-sm-12 col-md-12 col-lg-12">
                    <p className="marginBottom-0">
                        {insightsLbls.applicationAndPlayType}
                    </p>
                </div>

                <div className=" col-12 col-sm-12 col-md-12 col-lg-12">
                    <div className="displayFlex col-12 col-sm-12 col-md-12 col-lg-12 paddingLeftZero">
                        <p className='exportRequestFilterListTitleDiv warningMsg-text tooltipexport-warn marginTop-10 paddingLeftZero'>
                            {insightsLbls.appSelectionNote}
                        </p>
                    </div>
                    <div className="displayFlex col-12 col-sm-12 col-md-12 col-lg-12 paddingLeftZero">
                        <div className=" col-6 col-sm-6 col-md-6 col-lg-6 playTypeSelectionContainer paddingLeftZero">
                            <div className="playTypeSelectionTitle">
                                {insightsLbls.selectApplication}
                            </div>
                            {(appsFetched.isFetched && appsFetched.data.length > 0) &&
                                <GetAppDropdown appsList={appsFetched.data} insightsLbls={insightsLbls}
                                    selectedApps={appSelected} selectedAppsData={setSelectedAppDataHandler}></GetAppDropdown>}
                        </div>
                        <div className=" col-6 col-sm-6 col-md-6 col-lg-6 marginTop-10">
                            <div className="exportRequestFilterInformationDiv row-flex">
                                <div className='appPlayInfoDiv'>
                                    <div className="displayFlex col-12 col-sm-12 col-md-12 col-lg-12 positionRelative">
                                        {getAppsSelectionData()}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

                <hr></hr>
                {getDataSourceCard(dataSourceSelected)}
                <div className="exportRequestFilterInformationDiv row-flex">
                    <div className='appPlayInfoDiv'>
                        {selectedDataSourceData.includes("guide") && <> <hr></hr> {getPlayTypeCard(playTypeSelected)} </>}
                        {selectedAppData.length == 1 &&
                            <>
                                <hr></hr>
                                <div className="displayFlex col-12 col-sm-12 col-md-12 col-lg-12 paddingLeftZero">
                                    {getGuideTutorialSelectionContainer(selectedGuide, selectedTooltip)}
                                </div>
                            </>}
                    </div>
                </div>
            </div>
        </div >
    }

    return (
        <section className="width100 exportComponent" ref={componentRef}>
            <div className="exportDiv">
                <div className="exportListDiv">
                    <div className="exportListHeaderDiv">
                        <SectionButtons sectionTitle={insightsLbls.exportStatus} svgImage={"export"}>
                            <div className="marginLR-0 height30">
                                <i className="fa fa-refresh pull-right pointer row displayFlex refreshIcon margin0" aria-hidden="true" title={insightsLbls.refresh} onClick={() => { refreshList() }}></i>
                            </div>
                        </SectionButtons>
                    </div>
                    <hr style={exportList.listDataFetched ? {} : { margin: '1rem 0 0 0' }} />
                    <div className='templateContainer' style={{
                        position: "absolute", left: "370px", top: "86px",
                        visibility: ["exTemList", "exSchList"].includes(pageTab.key) ? "visible" : "hidden"
                    }}>
                        <div className="col-sm-12 col-md-12 col-lg-12 col-12 paddingLeftZero" style={{ minHeight: "40px", maxHeight: "40px" }}>
                            <button className="createCat-button borderRadius50P" onClick={() => {
                                pageTab.key == "exTemList" ? loadNewTemplate() : loadNewSchedule()
                            }}>
                                <span title={pageTab.key == "exTemList" ? insightsLbls.newTemplate : insightsLbls.newSchedule}>
                                    <i className="fa fa-plus" aria-hidden="true"></i>
                                </span>
                            </button>
                        </div>
                    </div>
                    <Tabs activeKey={pageTab.key}
                        onSelect={changeTab}
                        id="showMe-tabs">
                        <Tab eventKey={"exJobList"} title="Job List">
                            {getExportList()}
                        </Tab>
                        <Tab eventKey={"exTemList"} title="Template List">
                            {getTemplateList()}
                        </Tab>
                        <Tab eventKey={"exSchList"} title="Scheduler List">
                            {getSchedulerList()}
                        </Tab>
                    </Tabs>
                </div>
                {pageTab.key == "exJobList" && getNewJobTemplate()}
                {pageTab.key == "exTemList" && getTemplateCreationTemplate()}
                {pageTab.key == "exSchList" && getSchedulerTemplate()}
            </div>
            <CommonModal
                modalState={deleteModal.open}
                dispatchModalState={closeDeleteModal}
                modalTitle={deleteModal.optn == 'template' ? insightsLbls.deleteTemplate : insightsLbls.deleteSchedule}
                size={'md'}
                footerDisabled={false}
                exportModal={false}
                padding={true}
                modalDialogClass={''}
                dispatchModalOk={() => deleteFromModal()}
                dispatchModalCancel={closeDeleteModal}
                okBtn={insightsLbls.delete}
                cancelBtn={insightsLbls.cancel}
            >
                <div className="deleteChart">
                    {deleteModal.optn == 'template' ? insightsLbls.deleteTemplateConfirmationMsg : insightsLbls.deleteScheduleConfirmationMsg}
                </div>
            </CommonModal>
            <CommonModal
                modalState={playPauseModal.open}
                dispatchModalState={closePlayPauseModal}
                modalTitle={playPauseModal.optn == 'active' ? insightsLbls.deactivate : insightsLbls.activate}
                size={'md'}
                footerDisabled={false}
                exportModal={false}
                padding={true}
                modalDialogClass={''}
                dispatchModalOk={() => playPauseUpdate()}
                dispatchModalCancel={closePlayPauseModal}
                okBtn={insightsLbls.ok}
                cancelBtn={insightsLbls.cancel}
            >
                <div className="deleteChart">
                    {playPauseModal.optn == 'active' ? insightsLbls.deactivateScheduleMsg : insightsLbls.activateScheduleMsg}
                </div>
            </CommonModal>
        </section >
    );
}
export default withRouter(withReducer('export', exportReducer)(ExportList));