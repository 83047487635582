import React, { ChangeEvent, useState, ReactElement, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import ReactPaginate from "react-paginate";
import { Modal } from "react-bootstrap";
import withRouter from "hoc/withRouter";
import { useNavigate, useLocation } from 'react-router-dom';

import {Tooltip as ReactTooltip} from "react-tooltip";


import axios from "axios";
import { getCall } from "../../utils/ApiCallActions";
import { OrgState, LanguageState } from "store/Reducers";
import { setSectionVisibility, setOrg } from "store/Actions";
import { useErrorBoundary } from 'react-error-boundary';

import qs from "querystring";
import Loader from '../../components/Loader/Loader';
import "../../containers/SiteLayout/SiteLayout.scss";
import "../../style.scss";
import "./OrgSwitcher.scss";
import { RetryApi } from "utils/GlobalConstants";
import { InsightLabels } from "labels";


interface CurrentState {
    org: OrgState,
    languageData: LanguageState
}


const OrgSwitcher = (props: any): ReactElement => {
    const { showBoundary } = useErrorBoundary();
    const location = useLocation();
    const [insightsLbls, setInsightsLbls] = useState<InsightLabels>({} as InsightLabels);
    let orgDetails = useSelector((state: CurrentState) => state.org);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    let forcePage = 0;
    let cancelToken: any;

    let orgId = localStorage.getItem('ORG_SWITCH_ID');
    const [switchOrgData, setSwitchOrgData] = useState({
        loadData: false,
        orgSearchQuery: '',
        page: 1,
        totalCount: 0,
        orgList: [],
        selectedOrgId: orgId,
        org_card_check: false
    });

    const [openModal, setopenModal] = useState(false);

    let urlData: any = {};

    let fetched_details = useSelector((state: CurrentState) => state);

    useEffect(() => {
        if (fetched_details.languageData.languageData) {
            setInsightsLbls(fetched_details.languageData.languageData);
        }

    }, [fetched_details.languageData.languageData])

    const getOrgList = (page: number, search_text: string, apiErrorFlag?: string) => {
        try {
            if (typeof cancelToken !== typeof undefined) {
                cancelToken.cancel(insightsLbls['tokenCancelledMsg']);
            }

            cancelToken = axios.CancelToken.source();

            let params = {
                'page_index': page,
                'page_size': 10,
                'search_text': encodeURIComponent(search_text)
            };

            getCall(params, 'ORGANIZATION_LIST', apiErrorFlag ?? '').then((data: any) => {
                if (data.result === 'success') {
                    if (data.data !== undefined) {
                        setSwitchOrgData({
                            ...switchOrgData,
                            totalCount: data.data.totalCount,
                            orgList: data.data.organizations,
                            org_card_check: false,
                            loadData: true,
                            selectedOrgId: localStorage.getItem('ORG_SWITCH_ID')
                        })
                    }
                } else if (data.result === 'retry') {
                    setTimeout(() => {
                        getOrgList(switchOrgData.page, switchOrgData.orgSearchQuery, 'retry')
                    }, RetryApi.TIMEOUT)
                } else {

                }
            });
        } catch (error) {
            showBoundary(error)
        }

    }

    const changePaginationClick = (event: any) => {
        setSwitchOrgData({
            ...switchOrgData,
            page: event.selected + 1
        });
        // forcePage = event.se
        getOrgList(event.selected + 1, switchOrgData.orgSearchQuery);
    }


    const onSearchText = (e: ChangeEvent<HTMLInputElement>) => {
        try {
            forcePage = 0;
            getOrgList(1, e.target.value);
        } catch (error) {
            showBoundary(error)
        }


    }

    const rowSelected = (orgId: any) => {
        setSwitchOrgData({
            ...switchOrgData,
            selectedOrgId: orgId,
            org_card_check: true
        })
        localStorage.setItem('ORG_SWITCH_ID', orgId);
        switchOrganization(orgId);
    }

    useEffect(() => {
        const active_org = +localStorage.getItem('ORG_SWITCH_ID');
        console.log(active_org)
        if (active_org) {
            // switchOrganization(active_org, '')
        }
    }, [])


    const switchOrganization = (orgId: number, apiErrorFlag?: string) => {
        try {
            localStorage.setItem('ORG_SWITCH_ID', orgId.toString())
            localStorage.removeItem('SELECTED_APPS');
            localStorage.removeItem('min_date');
            localStorage.removeItem('max_date');
            localStorage.removeItem('firstApp_name');
            localStorage.removeItem('switch_app_id');
            localStorage.removeItem('INDEX_UP');
            localStorage.removeItem('INNER_INDEX_UP');
            localStorage.removeItem('ORG_NAME');
            localStorage.removeItem('ORG_IMG_URL');
            localStorage.removeItem('ORG_ID');
            localStorage.removeItem('USER_SIGN');


            const parameters = {
                'organization_id': orgId
            };

            getCall(parameters, 'SWITCH_ORGANIZATION', apiErrorFlag ?? '').then((data: any) => {
                if (data.result === 'success') {
                    if (data.data !== undefined) {
                        localStorage.removeItem('ACCESS_TOKEN');
                        localStorage.removeItem('DASHBOARD_APP_LIST')
                        localStorage.removeItem("ENV_FILTER_STATE");
                        localStorage.removeItem("eventSourceFilterState");
                        localStorage.removeItem("exportRequestSourceState");
                        localStorage.removeItem('ENABLE_REAL_TIME');

                        localStorage.setItem('ACCESS_TOKEN', data.data.AccessToken);
                        localStorage.setItem('ORG_NAME', data.data.organization.name);
                        localStorage.setItem('ORG_IMG_URL', data.data.organization.image_url);
                        localStorage.setItem('ORG_ID', data.data.organization.organization_id);
                        localStorage.setItem('USER_SIGN', data.data.organization.cdn_signature);
                        localStorage.setItem('ORG_SWITCH_ID', data.data.organization.organization_id);

                        const adminSettings = JSON.parse(data.data.organization.admin_settings);
                        let isPITrackingEnabled;
                        let myBot_flag;
                        let guideInsights_flag;
                        let appInsights_flag;
                        let qaAutomator_flag;
                        if ((adminSettings !== null || adminSettings !== undefined) && 'isPITrackingEnabled' in adminSettings) {
                            if (adminSettings.isPITrackingEnabled === 1 || adminSettings.isPITrackingEnabled === '1') {
                                localStorage.setItem('USER_ACTIVITY', 'true');
                                isPITrackingEnabled = true;
                            } else {
                                localStorage.setItem('USER_ACTIVITY', 'false');
                                isPITrackingEnabled = false;
                            }
                        } else {
                            localStorage.setItem('USER_ACTIVITY', 'false');
                        }

                        if (adminSettings.myBotEnabled === 1 || adminSettings.myBotEnabled === '1' || adminSettings.myBotEnabled === true) {
                            localStorage.setItem('MYBOT_VISIBILITY', 'true');
                            myBot_flag = true;
                        } else {
                            localStorage.setItem('MYBOT_VISIBILITY', 'false');
                            myBot_flag = false;
                        }

                        if ('guide' in adminSettings.insights && JSON.parse(adminSettings.insights.guide) == 1) {
                            localStorage.setItem('GUIDEINSIGHTS_VISIBILITY', 'true');
                            guideInsights_flag = true;
                        } else {
                            localStorage.setItem('GUIDEINSIGHTS_VISIBILITY', 'false');
                            guideInsights_flag = false;
                        }


                        if (Object.keys(adminSettings.insights.app).length) {
                            if ('app' in adminSettings.insights && adminSettings.insights.app[Object.keys(adminSettings.insights.app)[0]]['enable_page_tracking'] == 1) {
                                localStorage.setItem('APPINSIGHTS_VISIBILITY', 'true');
                                appInsights_flag = true;
                            } else {
                                localStorage.setItem('APPINSIGHTS_VISIBILITY', 'false');
                                appInsights_flag = false;
                            }
                        } else {
                            localStorage.setItem('APPINSIGHTS_VISIBILITY', 'false');
                            appInsights_flag = false;
                        }



                        if (adminSettings.workflowAutomationEnabled === 1 || adminSettings.workflowAutomationEnabled === '1' || adminSettings.workflowAutomationEnabled === true) {
                            localStorage.setItem('QAAUTOMATOR_VISIBILITY', 'true');
                            qaAutomator_flag = true;
                        } else {
                            localStorage.setItem('QAAUTOMATOR_VISIBILITY', 'false');
                            qaAutomator_flag = false;
                        }

                        if ('real_time_stats' in adminSettings.insights && JSON.parse(adminSettings.insights.real_time_stats) == 1) {
                            localStorage.setItem('ENABLE_REAL_TIME', 'true');
                        } else {
                            localStorage.setItem('ENABLE_REAL_TIME', 'false');
                        }

                        let sectionVisibility = {
                            superAdmin: localStorage.getItem('USER_ROLE') === 'superadmin',
                            isPITrackingEnabled: isPITrackingEnabled,
                            myBot_flag: myBot_flag,
                            guideInsights_flag: guideInsights_flag,
                            appInsights_flag: appInsights_flag,
                            qaAutomator_flag: qaAutomator_flag
                        }

                        dispatch(setSectionVisibility(sectionVisibility));

                        //if app insights is disabled (in react proj) then go to dashboard (angular proj)
                        // let urlPath = process.env.REACT_APP_INSIGHTS_DOMAIN;
                        // window.open(urlPath + "#/dashboard", "_self");

                        // props.dispatchOrgSwitch();

                        // document.title = "Dashboard";
                        var OrgData: object = {
                            orgSwitch: true,
                            orgId: data.data.organization.organization_id

                        }

                        dispatch(setOrg(OrgData));

                        setopenModal(false);
                        let queryParams = {
                            // app_code: "",
                        };
                        navigate({
                            pathname: `/dashboard/`,
                            search: qs.stringify(queryParams),
                        });


                    }
                } else if (data.result === 'retry') {
                    setTimeout(() => {
                        const id = localStorage.getItem('ORG_SWITCH_ID') ?? '0';
                        switchOrganization(+id, 'retry')
                    }, RetryApi.TIMEOUT)
                } else {

                }
            });
        } catch (error) {
            showBoundary(error)
        }

    }



    return (
        <div>
            <button className="switchOrg_btn"
                onClick={() => {
                    getOrgList(1, '');
                    setopenModal(true);
                    setSwitchOrgData({
                        ...switchOrgData,
                        loadData: false,
                    })
                }
                }
                disabled={window.location.href.includes('dashboard') || window.location.href.includes('organization')}
                style={{ cursor: (window.location.href.includes('dashboard') || window.location.href.includes('organization')) ? 'default' : 'pointer' }}
            >
                <img
                    src={require("../../assets/images/switchOrg.png")}
                />
            </button>

            <Modal onHide={() => setopenModal(false)} show={openModal} size={'lg'}>
                <Modal.Header>
                    <Modal.Title>{insightsLbls['switchOrganization']}</Modal.Title>
                    <button type="button" className="close" onClick={() => { setopenModal(false); }} >
                        <span aria-hidden="true">×</span>
                        <span className="sr-only">{insightsLbls['close']}</span>
                    </button>
                </Modal.Header>
                <Modal.Body className="displayFlex height100-percent orgModal" >
                    <div className="width-100-precent col-lg-12 height400 overflowAuto">
                        <div className="modal-body organisationSwitcher minHeight325 ">
                            <div className="row col-12 displayFlex margin0  switcherInput-div">
                                <input type="text" onChange={(event) => onSearchText(event)}
                                    autoComplete="off" className="searchIcon searchOrg form-control round-input" placeholder={insightsLbls['searchOrganization']} />
                            </div>
                            {switchOrgData.loadData
                                ? [
                                    (switchOrgData.orgList.length > 0
                                        ? (<div className="row col-12 margin0 paddingLR-0 listSection">
                                            {switchOrgData.orgList.map(function (orgData: any, index: any) {
                                                return (
                                                    // onClick = {() => rowSelected(orgData.organization_id)}
                                                    <div className="trRow displayInline-width100 switchOrganization borderOrg">
                                                        <div className={"org-row orgText-overflow check-container displayFlex "
                                                            + (orgData.organization_id === switchOrgData.selectedOrgId ? "org-active-row backgroundColorChecked" : "")}
                                                            style={switchOrgData.org_card_check ? { cursor: 'default' } : { cursor: 'pointer' }} onClick={() => rowSelected(orgData.organization_id)}>
                                                            <input id={"id_" + orgData.organization_id} type="radio" value={orgData.name} name="chk"
                                                                checked={+switchOrgData.selectedOrgId === +orgData.organization_id}

                                                                disabled={switchOrgData.org_card_check} />
                                                            <span className="checkmark checkmarkBox-color"></span> &nbsp;&nbsp;
                                                            <span className="overflowOrgName" data-tip data-for={"txtTooltip_" + orgData.organization_id} data-class="reactTooltipStyle" >
                                                                {orgData.name}
                                                                <ReactTooltip id={"txtTooltip_" + orgData.organization_id} place="bottom">{orgData.name}</ReactTooltip>
                                                            </span>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                            }
                                        </div>)
                                        : (<div className="row col-12 margin0 paddingLR-0 listSection">
                                            <div className="noData-div ">{insightsLbls.noRecordFound}</div>
                                        </div>
                                        )
                                    )
                                ]
                                : (<div className="loaderHeight width100 displayFlex height100-percent top-30 positionRelative">
                                    <div className="displayFlex alignCenter margin-0-auto">
                                        <Loader></Loader>
                                    </div>
                                </div>)
                            }
                        </div>
                    </div>
                </Modal.Body>

                <Modal.Footer>
                    <div className="paginationContainer">
                        {switchOrgData.loadData ?
                            (<ReactPaginate
                                previousLabel={<span aria-hidden="true">«</span>}
                                nextLabel={<span aria-hidden="true">»</span>}
                                breakLabel={"..."}
                                breakClassName={"break-me"}
                                pageCount={switchOrgData.totalCount / 10}
                                marginPagesDisplayed={2}
                                pageRangeDisplayed={3}
                                onPageChange={(event) => changePaginationClick(event)}
                                containerClassName={"pagination"}
                                forcePage={forcePage}
                                activeClassName={"active"} />)
                            : null
                        }
                    </div>

                </Modal.Footer>
            </Modal>
        </div>
    )

}

export default React.memo(withRouter(OrgSwitcher));